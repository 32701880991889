import {
  Alert,
  Card,
  Dialog,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import {
  finishRegister,
  finishRegisterEmail,
  getPersonne,
  handleLogin,
} from "../../services/auth"
import callApi from "../../utils/callApi"
import RoundTextField from "../forms/RoundTextField"
import { Controller, useForm, useController } from "react-hook-form"
import ButtonBlock from "../blocks/ButtonBlock/ButtonBlock"
import { participe } from "../../services/participants"
import { getNextEvenements } from "../../utils/EvenementsApiHelper"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

const ConfirmEmail = props => {
  const process = formations => {
    let prev = null
    const rows = []
    for (const formation of formations) {
      if (prev && prev.id !== formation.id) {
        rows.push(prev)
        prev = null
      }
      prev = formation
    }
    if (prev) rows.push(prev)
    // setFormations(rows)
  }
  const [formations, setFormations] = useState()

  const [confirmationStatus, setConfirmationStatus] = useState(null)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const evId = parseInt(searchParams.get("ev_id"))
  const token = searchParams.get("token")

  useEffect(() => {
    if (evId) {
      getNextEvenements().then(result => {
        // process(result.data.data)
        console.log(result.data.data)
        const formations = result.data.data
        let formation = formations.find(f => f.id === evId)
        if (formation) {
          setFormations(formation)
        } else {
          setFormations([])
        }
      })
    }
  }, [evId])

  // refreshData()
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isParticipated, setIsParticipated] = useState(false)
  const [message, setMessage] = useState(false)
  const [codeErr, setCodeErr] = useState(false)
  const [code] = useState()

  const [error, setError] = useState()
  const { control, watch, handleSubmit } = useForm({
    mode: "onTouched",
  })
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await callApi("post", "/api/auth/confirm", null, {
  //         token: props.token,
  //       })
  //       setIsConfirmed(true)
  //     } catch (e) {
  //       if (e.response?.data?.message) {
  //         setError(e.response.data.message)
  //       } else {
  //         setError("Erreur inconnue. Merci de réessayer plus tard.")
  //       }
  //     }
  //   }
  //   fetchData()
  // }, [props.token])
  const [isLoading, setIsLoading] = useState(false)

  const errorMessage = {
    civilite: {
      required: "Champ obligatoire",
    },
    nom: {
      required: "Champ obligatoire",
    },
    prenom: {
      required: "Champ obligatoire",
    },
    code: {
      required: "Champ obligatoire",
    },
    email: {
      // required: "Champ obligatoire",
      pattern: "Cette adresse email est invalide",
    },
    password: {
      required: "Un mot de passe est obligatoire",
      pattern:
        "Doit contenir au moins 1 lettre, 1 chiffre et un caractère spécial",
      minLength: "Doit contenir au moins 8 caractères",
      maxLength: "Doit contenir maximum 64 caractères",
    },
    PwdConfirmation: {
      required: "Un mot de passe est obligatoire",
      pattern:
        "Doit contenir au moins 1 lettre, 1 chiffre et un caractère spécial",
      minLength: "Doit contenir au moins 8 caractères",
      maxLength: "Doit contenir maximum 64 caractères",
      validate: "Le mot de passe ne correspond pas",
    },
    fixe: {
      minLength: "Doit contenir au moins 10 chiffres",
      maxLength: "Doit contenir au plus 10 chiffres",
      pattern: "Doit commencer par 01, 02, 03, 04, 05 ou 09",
    },
    mobile: {
      minLength: "Doit contenir au moins 10 chiffres",
      maxLength: "Doit contenir au plus 10 chiffres",
      pattern: "Doit commencer par 06 ou 07",
    },
    codePostal: {
      required: "Champ obligatoire",
      pattern: "Code postal invalide",
    },
  }

  const [personne, setPersonne] = useState()

  const fetchData = async () => {
    try {
      const response = await callApi(
        "post",
        `/api/auth/confirmEmail?evId=${evId}&token=${token}`
      )
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("personne", JSON.stringify(response.data.personne))
      }
      setConfirmationStatus("success")
      setIsConfirmed(true)
    } catch (e) {
      if (e.response?.data?.message) {
        setError(e.response.data?.message)
        setIsConfirmed(false)
        setConfirmationStatus("error")
      } else {
        setIsConfirmed(false)
        setError("Erreur inconnue. Merci de réessayer plus tard.")
        setConfirmationStatus("error")
      }
    }
  }
  // useEffect(() => {
  //   fetchData()
  // }, [])
  const [confirmed, setConfirmed] = useState(false)
  const [open, setOpen] = useState(false)
  const [confirmSms, setConfirmSms] = useState(false)
  const [personneInfo, setPersonneInfo] = useState()
  const confirmCode = async code => {
    setIsLoading(true)
    // console.log("event", code.code)
    const receivedCode = code.code
    try {
      const response = await callApi("post", `/api/auth/confirmCode`, null, {
        code: receivedCode,
      })
      setPersonneInfo(response.data.personne)
      if (response.data.success) {
        setCodeErr(false)

        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            "personne",
            JSON.stringify(response.data.personne)
          )
        }
        alert("votre numéro de mobile est confirmer !")
        setOpen(false)
        setIsParticipated(true)
      } else {
        alert("erreur est servenue")
      }
    } catch (err) {
      setCodeErr(true)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async data => {
    setIsLoading(true)
    const personne = await getPersonne()

    try {
      setError()
      // if (!personne) return
      const personneId = personne.id
      const personnex = {
        id: personneId,
        civilite: data.civilite,
        email: personne.email,
        nom: data.nom,
        prenom: data.prenom,
        mobile: data.mobile,
        codePostal: data.codePostal,
        fixe: data.fixe,
        profil: personne.profil,
        isConfirmed: personne.isConfirmed,
      }

      if (typeof localStorage !== "undefined") {
        const previousPersonne = JSON.parse(localStorage.getItem("personne"))
        const updatedPersonne = { ...previousPersonne, ...personnex }
        console.log("perssooon", updatedPersonne)
        await finishRegisterEmail(updatedPersonne)

        if (updatedPersonne.mobile) {
          try {
            const response = await callApi(
              "post",
              `/api/auth/sendExistedUserEmail`,
              null,
              {
                email: "",
                mobile: updatedPersonne.mobile,
                id: updatedPersonne.id,
              }
            )
            const result = await response.data

            if (result.success) {
              setConfirmSms(true)
              setOpen(true)

              const waitForOpenToClose = new Promise(resolve => {
                const interval = setInterval(() => {
                  if (!open && !isParticipated) {
                    clearInterval(interval)
                    resolve()
                  }
                }, 100)
              })

              await waitForOpenToClose
            } else {
              alert("erreur...")
            }
          } catch (error) {
            alert(error)
          }
        } else {
          setConfirmed(true)
          setIsParticipated(true)
        }
      }
      await participe(formations)
    } catch (err) {
      setError(err.response?.data?.message)
      setIsParticipated(false)
    } finally {
      setIsLoading(false)
    }
  }

  const goProfil = () => {
    navigate(`/mon-espace/priv/mon-compte/`)
  }
  return (
    <Card style={{ marginTop: 50, marginBottom: 100 }}>
      {confirmationStatus === "success" && (
        <Alert severity="success">
          Votre adresse email a bien été confirmée.
        </Alert>
      )}
      {confirmationStatus === "error" && (
        <>
          {error && (
            <>
              <Alert severity="warning">{error}</Alert>
            </>
          )}
        </>
      )}

      {!isConfirmed && !confirmed ? (
        <div  style={{ textAlign: "center" }}>
          <button onClick={fetchData}>Je confirme mon compte</button>
        </div>
      ) : (
        <></>
      )}

      {isConfirmed && !isParticipated && !confirmed ? (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="handleSubmit"
            className={"Formulaire-Modal__container"}
            style={{ margin: "0", paddingRight: "20%", paddingLeft: "20%" }}
          >
            <h3 style={{ textAlign: "center" }}>
              Veuillez remplir ce formulaire
            </h3>

            <div className="personne">
              <Controller
                name="civilite"
                control={control}
                defaultValue=""
                rules={{}}
                render={({ field, fieldState: { error: err } }) => (
                  <RoundTextField
                    {...field}
                    select
                    label="Civilité"
                    labelId="civiliteInput"
                    id="civilite"
                    fullWidth
                    error={err !== undefined}
                    helperText={err ? errorMessage.civilite[err.type] : " "}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"M"}>M</MenuItem>
                    <MenuItem value={"MME"}>Mme</MenuItem>
                  </RoundTextField>
                )}
              />

              <Controller
                name="nom"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error: err } }) => (
                  <RoundTextField
                    {...field}
                    label="Nom *"
                    type="text"
                    id="nom"
                    fullWidth
                    error={err !== undefined}
                    helperText={err ? errorMessage.nom[err.type] : " "}
                  />
                )}
              />

              <Controller
                name="prenom"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error: err } }) => (
                  <RoundTextField
                    {...field}
                    sx={{
                      width: {
                        desktop: "50%",
                        tablet: "47%",
                        laptop: "47%",
                        mobile: "100%",
                      },
                    }}
                    tablet={5}
                    label="Prénom *"
                    type="text"
                    id="prenom"
                    fullWidth
                    error={err !== undefined}
                    helperText={err ? errorMessage.prenom[err.type] : " "}
                  />
                )}
              />

              <Stack direction={"row"} justifyContent="space-between">
                <Controller
                  name="mobile"
                  control={control}
                  defaultValue=""
                  rules={{
                    minLength: 10,
                    maxLength: 10,
                    pattern: /^0[67]/,
                  }}
                  render={({ field, fieldState: { error: err } }) => (
                    <RoundTextField
                      {...field}
                      sx={{
                        width: {
                          desktop: "30%",
                          tablet: "30%",
                          laptop: "47%",
                          mobile: "100%",
                        },
                      }}
                      label="Numéro de mobile"
                      type="tel"
                      error={err !== undefined}
                      helperText={err ? errorMessage.mobile[err.type] : " "}
                    />
                  )}
                />

                <Controller
                  name="fixe"
                  control={control}
                  defaultValue=""
                  rules={{
                    minLength: 10,
                    maxLength: 10,
                    pattern: /^0[1234589]/,
                  }}
                  render={({ field, fieldState: { error: err } }) => (
                    <RoundTextField
                      {...field}
                      sx={{
                        width: {
                          desktop: "30%",
                          tablet: "30%",
                          laptop: "47%",
                          mobile: "100%",
                        },
                      }}
                      label="Numéro de fixe"
                      type="tel"
                      error={err !== undefined}
                      helperText={err ? errorMessage.fixe[err.type] : " "}
                    />
                  )}
                />
                <Controller
                  name="codePostal"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
                  }}
                  render={({ field, fieldState: { error: err } }) => (
                    <RoundTextField
                      {...field}
                      sx={{
                        width: {
                          desktop: "30%",
                          tablet: "30%",
                          laptop: "47%",
                          mobile: "100%",
                        },
                      }}
                      label="code postal *"
                      type="text"
                      id="codePostal"
                      error={err !== undefined}
                      helperText={err ? errorMessage.codePostal[err.type] : " "}
                    />
                  )}
                />
              </Stack>
            </div>

            <div className="Formulaire__message">
              <p className="small">
                <small>
                  <sup>*</sup> Champs obligatoires
                </small>
              </p>
            </div>
            {error && (
              <>
                <Alert severity="error">{error}</Alert>
              </>
            )}

            <div className="Formulaire__btn">
              <ButtonBlock
                color="black"
                text={isLoading ? "Chargement..." : "Envoyer"}
                type="submit"
                disabled={isLoading}
                form="handleSubmit"
              ></ButtonBlock>
            </div>
            <div style={{ textAlign: "center" }}></div>
          </form>
          <Dialog open={open}>
            <DialogTitle align="center">
              {confirmSms ? (
                <form onSubmit={handleSubmit(confirmCode)} id="confirmCodeForm">
                  <p style={{ marginTop: 50, marginBottom: 50 }}>
                    Vous avez enregistré un numéro de mobile, un SMS vous a été
                    envoyé. Veuillez saisir le code :
                  </p>
                  {codeErr ? (
                    <Alert severity="error">Code incorrect !</Alert>
                  ) : (
                    <></>
                  )}
                  <p></p>
                  <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    value={code}
                    rules={{
                      required: true,
                    }}
                    render={({ field, fieldState: { error: err } }) => (
                      <RoundTextField
                        {...field}
                        tablet={5}
                        label="Code *"
                        type="number"
                        id="code"
                        fullWidth
                        error={err !== undefined}
                        helperText={err ? errorMessage.code[err.type] : " "}
                      />
                    )}
                  />
                  <button
                    type="submit"
                    form="confirmCodeForm"
                    disabled={isLoading}
                  >
                    {isLoading ? "Chargement..." : "Envoyer"}
                  </button>
                </form>
              ) : (
                <></>
              )}
            </DialogTitle>
          </Dialog>
        </>
      ) : (
        isParticipated && (
          <>
            <p style={{ textAlign: "center" }}>
              vous êtes inscrit a la formation :{" "}
              {formations && formations.evenement_type_titre === "Autre" ? (
                <strong>{formations.theme_autre_titre}</strong>
              ) : (
                <>
                  <strong>{formations.evenement_type_titre}</strong>
                </>
              )}
            </p>
            <div className="Formulaire__btn">
              <ButtonBlock
                color="black"
                text={"close"}
                onClickTrue={goProfil}
              ></ButtonBlock>
            </div>
          </>
        )
      )}
    </Card>
  )
}
export default ConfirmEmail
