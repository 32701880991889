import { createFilterOptions, Popper } from "@mui/material"
import React from "react"
import callApi from "../../../utils/callApi"
import AsyncAutocomplete from "../../forms/AsyncAutocomplete"

async function loadEtablissements() {
  try {
    const data = await callApi("get", "/api/etablissements/");
    return [...data.data.data];
  } catch (err) {
    console.log(err.message);
    throw err;
  }
}

const LargePopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  )
}

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: option =>
    option.entiteJuridique + " " + option.ville + " " + option.code,
})

const AutocompleteEtablissement = ({ ...props }) => {
  return (
    <AsyncAutocomplete
      {...props}
      label="Lieu"
      asyncLoadData={loadEtablissements}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option?.entiteJuridique || ""}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.entiteJuridique}
          <br />
          {`${option.ville} (${option.code})`}
        </li>
      )}
      filterOptions={filterOptions}
      PopperComponent={LargePopper}
    />
  )
}
export default AutocompleteEtablissement
