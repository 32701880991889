import { Chip, Popper } from "@mui/material"
import React from "react"
import AsyncAutocomplete from "../../forms/AsyncAutocomplete"

async function loadStatuts() {
  return [
    { name: "Nouvelle", color: "#F7C4EC" },
    { name: "Soumise", color: "#FFE9CB" },
    { name: "Publiée", color: "#FFBC3E" },
    { name: "En cours", color: "#EDEDB1" },
    { name: "Terminée", color: "#DAD320" },
    { name: "Annulée", color: "#FF5C00" },
    { name: "Reportée", color: "#C4C4C4" },
  ]
}

const LargePopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  )
}

const AutocompleteStatut = ({ ...props }) => {
  return (
    <AsyncAutocomplete
      {...props}
      //disableCloseOnSelect
      label="Statut"
      asyncLoadData={loadStatuts}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={option => option.name}
      PopperComponent={LargePopper}
      renderOption={(props, option, { selected }) => {
        return (
          !selected && (
            <li
              {...props}
              style={{ backgroundColor: option.color, borderRadius: 20 }}
              key={option.name}
            >
              {" "}
              {option.name}
            </li>
          )
        )
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            style={{ backgroundColor: option.color }}
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  )
}
export default AutocompleteStatut
