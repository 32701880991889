import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import {
  addParticipanteToEvenement,
  getParticipantsList,
  getParticipantsNotifications,
  getPersonne,
  getPersonneNotification,
} from "../../../services/auth"
import callApi from "../../../utils/callApi"
import FreeEmailEditor from "../../FreeEmailEditor"
import AutocompleteParticipant from "./AutocompleteParticipant"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import excelExport from "../../../utils/excelHelper"
import ButtonBlock from "../../blocks/ButtonBlock/ButtonBlock"
import CostumBtn from "../../blocks/ButtonBlock/CostumBtn"
import CheckBoxFieldBlock from "../../blocks/CheckBoxFieldBlock/CheckBoxFieldBlock"
import FormationParticipantListNotificationDialog from "./FormationParticipantListNotificationDialog/FormationParticipantListNotificationDialog"
import FormationParticipantsListNotifications from "./FormationParticipantsListNotifications/FormationParticipantsListNotifications"
import { formatDate } from "../../../utils/dateHelper"

const ParticipantsList = ({ ev_id, isReadOnly, formation }) => {
  const [participants, setParticipants] = useState([])
  const [emailSent, setEmailSent] = useState(false)
  const [smsSent, setSmsSent] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [smsError, setSmsError] = useState(false)
  const [participantNotifications, setParticipantNotifications] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [clickedType, setClickedType] = useState(null)
  const [currentParticipant, setCurrentParticipant] = useState(null)

  const [addParticipantOpen, setAddParticipantOpen] = useState(false)
  const [addedParticipant, setAddedParticipant] = useState(false)
  const [addParticipantError, setAddParticipantError] = useState(false)
  const [addParticipantErrorMessage, setAddParticipantErrorMessage] =
    useState("")
  const [currentNotification, setCurrentNotification] = useState(null)
  const [selectedParticipantId, setSelectedParticipantId] = useState(null)
  const person = getPersonne()
  const isAdmin = person.profil === "Administrateur"
  const loadParticipants = useCallback(async () => {
    try {
      const data = await callApi("get", "/api/formation/participants", {
        id: ev_id,
      })
      setParticipants(data.data.participants)
    } catch (err) {
      console.log(err.message)
    }
  }, [ev_id])
  
  let evenementTitle =
    formation && formation?.evenement_type.titre === "Autre"
      ? formation?.theme_autre_titre
      : formation?.evenement_type.titre
  let evenementDate = formation && formation.date_debut


  const getParticipantsLists = async () => {
    try {
      const data = await getParticipantsList({
        evId: ev_id,
      })

      const flattenedData = data.data.response.reduce((result, user) => {
        if (user && user.length > 0) {
          console.log("Entering loop for user:", user)
          user.lastNotifications.forEach((notification, index) => {
            if (
              notification &&
              notification.latestNotification_type_notification_label
            ) {
              console.log(
                "Before key generation:",
                notification.latestNotification_type_notification_label
              )
              const key = `${notification.latestNotification_type_notification_label.replace(
                /[^a-zA-Z0-9]/g,
                "_"
              )}_${index + 1}`
              console.log("Generated key:", key)
              result.push({
                ...flattenObject(user),
                ...flattenObject(notification, key),
              })
            }
          })
        } else {
          result.push({
            ...flattenObject(user),
          })
        }
        return result
      }, [])
      excelExport(
        flattenedData,
        `Liste des données ${ev_id}`,
        `Participants ${evenementTitle} ${evenementDate} ${ev_id}.xlsx`
      )
    } catch (err) {
      console.log(err.message)
    }
  }

  // Function to flatten nested objects
  const flattenObject = (obj, prefix = "") => {
    return Object.keys(obj).reduce((acc, key) => {
      const propName = prefix ? `${prefix}_${key}` : key
      if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(acc, flattenObject(obj[key], propName))
      } else {
        acc[propName] = obj[key]
      }
      return acc
    }, {})
  }

  const getParticipantsNotificationss = async () => {
    try {
      const data = await getParticipantsNotifications({
        evId: ev_id,
      })
      const groupedNotifications = data.data.notif.reduce(
        (acc, notification) => {
          const userId = notification.notifications_destinataire
          if (!acc[userId]) {
            acc[userId] = []
          }
          acc[userId].push(notification)
          return acc
        },
        {}
      )

      Object.keys(groupedNotifications).forEach(userId => {
        groupedNotifications[userId].sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        )
      })

      Object.keys(groupedNotifications).forEach(userId => {
        const sheet = groupedNotifications[userId]
        excelExport(
          sheet,
          `Notifications ${ev_id}`,
          `Notifications ${evenementTitle} ${evenementDate} ${ev_id}.xlsx`
        )
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getCurrentPersonneNotifications = async (personneId, evId, index) => {
    try {
      const data = await getPersonneNotification({
        personneId: personneId,
        evId: evId,
      })
      setParticipantNotifications(prevNotifications => {
        const updatedNotifications = [...prevNotifications]
        updatedNotifications[index] = {
          notifications: data.data.notification,
          questionnaireUrl: data.data.surveryUrlQuestionnaire,
          evaluationUrl: data.data.surveryUrlEvaluation,
        }
        return updatedNotifications
      })
    } catch (err) {
      console.log(err.message)
    }
  }


  const handleCloseAddParticipant = () => {
    setAddParticipantOpen(false)
  }
  const handleOpenAddParticipant = () => {
    setAddParticipantOpen(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setClickedType(null)
    setEmailSent(false)
    setSmsSent(false)
  }

  const getNotificationLabel = code => {
    switch (code) {
      case "INSCRIPTION_CONFIRMEE":
        return "Confirmation"
      case "RAPPEL_PARTICIPATION_15_JOURS":
        return "J - 15"
      case "RAPPEL_PARTICIPATION_7_JOURS":
        return "J - 7"
      case "RAPPEL_PARTICIPATION_1_JOUR":
        return "J - 1"
      case "QUESTIONNAIRE":
        return "QUESTIONNAIRE"
      case "EVALUATION":
        return "EVALUATION"
      default:
        return "Default Label"
    }
  }

  const handleOpenDialog = (type, p, groupedNotifications) => {
    setClickedType(type)
    setCurrentParticipant(p)
    setCurrentNotification(groupedNotifications)
    setOpenDialog(true)
  }
  const renderDialogContent = p => {
    if (!clickedType) {
      return null
    }

    return (
      <FormationParticipantListNotificationDialog
        openDialog={openDialog}
        clickedType={clickedType}
        currentParticipant={currentParticipant}
        currentNotification={currentNotification}
        setSmsSent={setSmsSent}
        smsSent={smsSent}
        setSmsError={setSmsError}
        smsError={smsError}
        setEmailSent={setEmailSent}
        emailSent={emailSent}
        setEmailError={setEmailError}
        emailError={emailError}
        handleCloseDialog={handleCloseDialog}
        getNotificationLabel={getNotificationLabel}
        formatDate={formatDate}
        ev_id={ev_id}
      />
    )
  }

  useEffect(() => {
    const load = async () => {
      ev_id !== "new" && loadParticipants()
    }
    load()
  }, [ev_id, loadParticipants])

  const delParticipant = async personne_id => {
    try {
      const data = await callApi("post", "/api/participants/unsubscribe", null, {
        ev_id,
        personne_id,
        should_not_send_emails: true,
      })
      await loadParticipants()
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleParticipantChange = (event, value) => {
    setSelectedParticipantId(prevId => {
      return value ? value : null
    })
  }

  const handleAjouterClick = async () => {
    try {
      if (selectedParticipantId) {
        console.log("Selected Participant ID:", selectedParticipantId)
        console.log("formation", formation)
        const formationResponse = await callApi(
          "get",
          "/api/formation/evenement",
          {
            id: ev_id,
          }
        )

        console.log(formationResponse)
        await addParticipanteToEvenement({
          personne: selectedParticipantId,
          formation: formationResponse.data.evenement,
        })
      }
      setAddedParticipant(true)
      setAddParticipantError(false)
    } catch (error) {
      setAddParticipantError(true)
      setAddedParticipant(false)
      setAddParticipantErrorMessage(error.response.data.message)
      console.error(error)
    }
  }

  return (
    ev_id !== "new" && (
      <>
        <div>
          <h4>Participants</h4>
        </div>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-evenly"}
        >
          {participants.map((p, i) => {
            const currentParticipantNotifications =
              participantNotifications[i] || {}

            const groupedNotifications =
              currentParticipantNotifications.notifications
                ? currentParticipantNotifications.notifications.reduce(
                    (acc, notif) => {
                      const type = notif.type_notification_code

                      if (!acc[type]) {
                        acc[type] = {
                          isSMS: false,
                          isEmail: false,
                          data: [],
                        }
                      }

                      if (notif.media === "SMS") {
                        acc[type].isSMS = true
                      } else if (notif.media === "Email") {
                        acc[type].isEmail = true
                      }

                      if (
                        !acc[type].sent_on ||
                        new Date(notif.sent_on) > new Date(acc[type].sent_on)
                      ) {
                        acc[type].sent_on = notif.sent_on
                        acc[type].media = notif.media
                        acc[type].type_notification_code =
                          notif.type_notification_code
                      }

                      acc[type].data.push(notif)

                      return acc
                    },
                    {}
                  )
                : {}
            if (currentParticipantNotifications.questionnaireUrl) {
              groupedNotifications.questionnaireUrl = {
                data: currentParticipantNotifications.questionnaireUrl,
              }
            }

            if (currentParticipantNotifications.evaluationUrl) {
              groupedNotifications.evaluationUrl = {
                data: currentParticipantNotifications.evaluationUrl,
              }
            }
            return (
              <Box
                key={i}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <Accordion
                  square={true}
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    backgroundColor: "none",
                    boxShadow: "none",
                    borderTop: "1px solid black",
                    borderTopFeftRadius: "0px",
                    borderRadius: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      gap: "20px",
                      "& .MuiAccordionSummary-content": {},
                    }}
                    onClick={async () => {
                      await getCurrentPersonneNotifications(
                        p.personne_id,
                        ev_id,
                        i
                      )
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { lg: "row", xs: "column" },
                        width: "100%",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box width={"100%"}>
                        <div
                          style={{
                            height: "10px",
                            width: "10px",
                            backgroundColor: !p.dateDesinscription? "rgba(26, 184, 0, 1)": "rgba(255, 0, 0, 1)",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </Box>
                      <Box width={"100%"}>
                        <strong>{p.nom}</strong>
                      </Box>
                      <Box width={"100%"}>
                        <strong>{p.prenom}</strong>
                      </Box>
                      <Box width={"100%"}>
                        {p.email && p.email_confirmation_date ? (
                          <Box width={"100%"}>
                            <span>{p.email}</span>
                          </Box>
                        ) : (
                          <Box width={"100%"}>
                            <span>email non fourni</span>
                          </Box>
                        )}
                      </Box>
                      <Box width={"100%"}>
                        {p.mobile && p.mobile_confirmation_date ? (
                          <Box width={"100%"}>
                            <span>{p.mobile}</span>
                          </Box>
                        ) : (
                          <Box width={"100%"}>
                            <span>numéro non fourni</span>
                          </Box>
                        )}
                      </Box>
                      <Box width={"100%"}>
                        <span>{p.code_postal}</span>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: { lg: "row", xs: "column" },
                      justifyContent: "space-between",
                    }}
                  >
                    <React.Fragment>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: groupedNotifications[
                            "INSCRIPTION_CONFIRMEE"
                          ]?.sent_on
                            ? "center"
                            : "flex-start",
                          border: "1px solid black",
                          padding: "15px 0px 15px 0px",
                          flex: "1",
                          gap: "11px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenDialog(
                            "INSCRIPTION_CONFIRMEE",
                            p,
                            groupedNotifications,
                            i
                          )
                        }
                      >
                        <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                          <FormationParticipantsListNotifications
                            groupedNotifications={
                              groupedNotifications["INSCRIPTION_CONFIRMEE"]
                                ? true
                                : false
                            }
                            notifCode={
                              groupedNotifications["INSCRIPTION_CONFIRMEE"]
                                ?.type_notification_code
                            }
                            text="CONFIRMATION"
                            sentOn={
                              groupedNotifications["INSCRIPTION_CONFIRMEE"]
                                ?.sent_on
                            }
                            isEmail={
                              groupedNotifications["INSCRIPTION_CONFIRMEE"]
                                ?.isEmail
                            }
                            isSMS={
                              groupedNotifications["INSCRIPTION_CONFIRMEE"]
                                ?.isSMS
                            }
                          />
                        </Box>
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "10px",
                          }}
                        >
                          {groupedNotifications["INSCRIPTION_CONFIRMEE"]
                            ? "INSCRIPTION " +
                              formatDate(
                                groupedNotifications["INSCRIPTION_CONFIRMEE"]
                                  ?.sent_on,
                                "fr-FR" // French locale
                              )
                            : ""}
                        </span>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: groupedNotifications[
                            "RAPPEL_PARTICIPATION_15_JOURS"
                          ]?.sent_on
                            ? "center"
                            : "flex-start",
                          border: "1px solid black",
                          padding: "15px 0px 15px 0px",
                          flex: "1",
                          gap: "11px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenDialog(
                            "RAPPEL_PARTICIPATION_15_JOURS",
                            p,
                            groupedNotifications
                          )
                        }
                      >
                        {" "}
                        <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                          <FormationParticipantsListNotifications
                            groupedNotifications={
                              groupedNotifications[
                                "RAPPEL_PARTICIPATION_15_JOURS"
                              ]
                                ? true
                                : false
                            }
                            notifCode={
                              groupedNotifications[
                                "RAPPEL_PARTICIPATION_15_JOURS"
                              ]?.type_notification_code
                            }
                            text="J - 15"
                            sentOn={
                              groupedNotifications[
                                "RAPPEL_PARTICIPATION_15_JOURS"
                              ]?.sent_on
                            }
                            isEmail={
                              groupedNotifications[
                                "RAPPEL_PARTICIPATION_15_JOURS"
                              ]?.isEmail
                            }
                            isSMS={
                              groupedNotifications[
                                "RAPPEL_PARTICIPATION_15_JOURS"
                              ]?.isSMS
                            }
                          />
                        </Box>
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "10px",
                          }}
                        >
                          {groupedNotifications["RAPPEL_PARTICIPATION_15_JOURS"]
                            ? formatDate(
                                groupedNotifications
                                  .RAPPEL_PARTICIPATION_15_JOURS.sent_on
                              )
                            : ""}
                        </span>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: groupedNotifications[
                            "RAPPEL_PARTICIPATION_7_JOURS"
                          ]?.sent_on
                            ? "center"
                            : "flex-start",
                          border: "1px solid black",
                          padding: "15px 0px 15px 0px",
                          flex: "1",
                          gap: "11px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenDialog(
                            "RAPPEL_PARTICIPATION_7_JOURS",
                            p,
                            groupedNotifications
                          )
                        }
                      >
                        <FormationParticipantsListNotifications
                          groupedNotifications={
                            groupedNotifications["RAPPEL_PARTICIPATION_7_JOURS"]
                              ? true
                              : false
                          }
                          notifCode={
                            groupedNotifications["RAPPEL_PARTICIPATION_7_JOURS"]
                              ?.type_notification_code
                          }
                          text="J - 7"
                          sentOn={
                            groupedNotifications["RAPPEL_PARTICIPATION_7_JOURS"]
                              ?.sent_on
                          }
                          isEmail={
                            groupedNotifications["RAPPEL_PARTICIPATION_7_JOURS"]
                              ?.isEmail
                          }
                          isSMS={
                            groupedNotifications["RAPPEL_PARTICIPATION_7_JOURS"]
                              ?.isSMS
                          }
                        />
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "10px",
                          }}
                        >
                          {groupedNotifications["RAPPEL_PARTICIPATION_7_JOURS"]
                            ? formatDate(
                                groupedNotifications
                                  .RAPPEL_PARTICIPATION_7_JOURS.sent_on
                              )
                            : ""}
                        </span>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: groupedNotifications[
                            "RAPPEL_PARTICIPATION_1_JOUR"
                          ]?.sent_on
                            ? "center"
                            : "flex-start",
                          border: "1px solid black",
                          padding: "15px 0px 15px 0px",
                          flex: "1",
                          gap: "11px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenDialog(
                            "RAPPEL_PARTICIPATION_1_JOUR",
                            p,
                            groupedNotifications
                          )
                        }
                      >
                        <FormationParticipantsListNotifications
                          groupedNotifications={
                            groupedNotifications["RAPPEL_PARTICIPATION_1_JOUR"]
                              ? true
                              : false
                          }
                          notifCode={
                            groupedNotifications["RAPPEL_PARTICIPATION_1_JOUR"]
                              ?.type_notification_code
                          }
                          text="J - 1"
                          sentOn={
                            groupedNotifications["RAPPEL_PARTICIPATION_1_JOUR"]
                              ?.sent_on
                          }
                          isEmail={
                            groupedNotifications["RAPPEL_PARTICIPATION_1_JOUR"]
                              ?.isEmail
                          }
                          isSMS={
                            groupedNotifications["RAPPEL_PARTICIPATION_1_JOUR"]
                              ?.isSMS
                          }
                        />
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "10px",
                          }}
                        >
                          {groupedNotifications["RAPPEL_PARTICIPATION_1_JOUR"]
                            ? formatDate(
                                groupedNotifications.RAPPEL_PARTICIPATION_1_JOUR
                                  .sent_on
                              )
                            : ""}
                        </span>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: groupedNotifications["QUESTIONNAIRE"]
                            ?.sent_on
                            ? "center"
                            : "flex-start",
                          border: "1px solid black",
                          padding: "15px 0px 15px 0px",
                          flex: "1",
                          gap: "11px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenDialog(
                            "QUESTIONNAIRE",
                            p,
                            groupedNotifications
                          )
                        }
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <FormationParticipantsListNotifications
                            groupedNotifications={
                              groupedNotifications["QUESTIONNAIRE"]
                                ? true
                                : false
                            }
                            notifCode={
                              groupedNotifications["QUESTIONNAIRE"]
                                ?.type_notification_code
                            }
                            media={groupedNotifications["QUESTIONNAIRE"]?.media}
                            text="QUESTIONNAIRE"
                            sentOn={
                              groupedNotifications["QUESTIONNAIRE"]?.sent_on
                            }
                            isEmail={
                              groupedNotifications["QUESTIONNAIRE"]?.isEmail
                            }
                            isSMS={groupedNotifications["QUESTIONNAIRE"]?.isSMS}
                          />
                          <CheckBoxFieldBlock
                            style={{
                              margin: "0",
                              padding: "0",
                              height: "13px",
                              width: "13px",
                            }}
                            icon={
                              <RadioButtonUncheckedIcon
                                height="13px"
                                width="13px"
                                fontSize="13px"
                              />
                            }
                            disabled
                            checkedIcon={
                              p.questionnaire ? (
                                <CheckCircleIcon fontSize="13px" />
                              ) : (
                                <RadioButtonUncheckedIcon
                                  height="13px"
                                  width="13px"
                                  fontSize="13px"
                                />
                              )
                            }
                            checked={p.questionnaire ? true : false}
                          />
                        </Box>
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "10px",
                          }}
                        >
                          {groupedNotifications["QUESTIONNAIRE"]
                            ? formatDate(
                                groupedNotifications.QUESTIONNAIRE.sent_on
                              )
                            : ""}
                        </span>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: groupedNotifications["EVALUATION"]
                            ?.sent_on
                            ? "center"
                            : "flex-start",
                          border: "1px solid black",
                          padding: "15px 0px 15px 0px",
                          flex: "1",
                          gap: "11px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenDialog(
                            "EVALUATION",
                            p,
                            groupedNotifications
                          )
                        }
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <FormationParticipantsListNotifications
                            groupedNotifications={
                              groupedNotifications["EVALUATION"] ? true : false
                            }
                            notifCode={
                              groupedNotifications["EVALUATION"]
                                ?.type_notification_code
                            }
                            media={groupedNotifications["EVALUATION"]?.media}
                            text="EVALUATION"
                            sentOn={groupedNotifications["EVALUATION"]?.sent_on}
                            isEmail={
                              groupedNotifications["EVALUATION"]?.isEmail
                            }
                            isSMS={groupedNotifications["EVALUATION"]?.isSMS}
                          />
                          <CheckBoxFieldBlock
                            style={{
                              margin: "0",
                              padding: "0",
                              height: "13px",
                              width: "13px",
                            }}
                            disabled
                            icon={<RadioButtonUncheckedIcon fontSize="13px" />}
                            checkedIcon={
                              p.evaluation ? (
                                <CheckCircleIcon height="13px" width="13px" fontSize="13px" />
                              ) : (
                                <RadioButtonUncheckedIcon
                                  height="13px"
                                  width="13px"
                                  fontSize="13px"
                                />
                              )
                            }
                            checked={p.evaluation ? true : false}
                          />
                        </Box>
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: "10px",
                          }}
                        >
                          {groupedNotifications["EVALUATION"]
                            ? formatDate(
                                groupedNotifications.EVALUATION.sent_on
                              )
                            : ""}
                        </span>
                      </Box>
                      {renderDialogContent(p, i)}
                    </React.Fragment>
                  </AccordionDetails>

                  <AccordionActions>
                    {!isReadOnly && (
                      <>
                        <CostumBtn
                          disabled={p.dateDesinscription}
                          className=""
                          text={"Désinscrire"}
                          border={"1px solid red"}
                          fontSize={"12px"}
                          borderRadius={"10px"}
                          color={"rgba(255, 0, 0, 1)"}
                          borderColor={"rgba(255, 0, 0, 1)"}
                          boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                          iconName={p.dateDesinscription ? "" : "close"}
                          iconColor={"red"}
                          iconStroke={"red"}
                          iconHeight={"10px"}
                          iconWidth={"10px"}
                          onClick={() => {
                            if (
                              window.confirm(
                                "Êtes-vous sûr de vouloir désinscrire ce participant ?"
                              )
                            ) {
                              delParticipant(p.personne_id)
                            }
                          }}
                        ></CostumBtn>
                        &nbsp;
                        <FreeEmailEditor
                          ev_id={ev_id}
                          personne_id={p.personne_id}
                          recipient_type="PARTICIPANT"
                          recipient_string={`${p.nom} ${p.prenom} <${p.email}>`}
                          borderRadius
                        />
                      </>
                    )}
                  </AccordionActions>
                </Accordion>
              </Box>
            )
          })}
          <Box
            display={"flex"}
            alignSelf={"flex-end"}
            gap={"10px"}
            flexWrap={"wrap"}
            alignItems={"center"}
          >
            <Box>
              <ButtonBlock
                text={"Ajouter un participant"}
                onClickTrue={handleOpenAddParticipant}
              ></ButtonBlock>
              <Dialog
                open={addParticipantOpen}
                onClose={handleCloseAddParticipant}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Ajouter un participant"}
                </DialogTitle>
                <DialogContent>
                  {addedParticipant ? (
                    <Alert severity="success">
                      Ce participant a bien ajouté à la formation !
                    </Alert>
                  ) : (
                    addParticipantError && (
                      <Alert severity="error">
                        {addParticipantErrorMessage}
                      </Alert>
                    )
                  )}
                </DialogContent>
                <DialogActions>
                  <Stack direction={"column"} style={{ width: 500 }}>
                    {!isReadOnly && (
                      <>
                        <AutocompleteParticipant
                          fullWidth
                          freeSolo
                          onChange={handleParticipantChange}
                        />
                        &nbsp;
                      </>
                    )}
                    <Box>
                      <Button onClick={handleAjouterClick}>Ajouter</Button>
                      <Button onClick={handleCloseAddParticipant}>
                        fermer
                      </Button>
                    </Box>
                  </Stack>
                </DialogActions>
              </Dialog>
            </Box>
            {isAdmin && (
              <Box>
                <ButtonBlock
                  onClickTrue={getParticipantsNotificationss}
                  text={"Exporter les notifications"}
                ></ButtonBlock>
              </Box>
            )}
            <Box>
              <ButtonBlock
                onClickTrue={getParticipantsLists}
                text={"Exporter les données des participants"}
              ></ButtonBlock>
            </Box>
          </Box>
        </Box>
      </>
    )
  )
}

export default ParticipantsList
