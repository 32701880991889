import * as React from "react"

import moment from "moment"
import "moment/locale/fr"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import RoundTextField from "./RoundTextField"
import ClearIcon from "@mui/icons-material/Clear"
import TextFieldBlock from "../blocks/TextFieldBlock/TextFieldBlock"

export default function FrenchTimePicker({setValue, getValue, erasable, ...props }) {
  moment.locale("fr")

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"fr-FR"}>
      <TimePicker
        renderInput={params => (
          <TextFieldBlock
          style={{width:200}} 
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {erasable && getValue() !== null && (
                    <ClearIcon
                      onClick={e => {
                        setValue(null)
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  )
}
