import { Popper } from "@mui/material"
import React from "react"
import callApi from "../../../utils/callApi"
import AsyncAutocomplete from "../../forms/AsyncAutocomplete"

async function loadParticipants() {
  try {
    const data = await callApi("get", "/api/personnes/participants")
    return [...data.data.data]
  } catch (err) {
    console.log(err.message)
  }
}

const LargePopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  )
}

const AutocompleteParticipant = ({ ...props }) => {
  return (
    <AsyncAutocomplete
      {...props}
      label="Participant"
      asyncLoadData={loadParticipants}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option =>
        `${option.nom} ${option.prenom} <${option.email}>`
      }
      getOptionSelected={(option, value) => option.id === value.id}
      PopperComponent={LargePopper}
    />
  )
}
export default AutocompleteParticipant
