import * as React from "react"

import moment from "moment"
import "moment/locale/fr"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import RoundTextField from "./RoundTextField"
import ClearIcon from "@mui/icons-material/Clear"
import TextFieldBlock from "../blocks/TextFieldBlock/TextFieldBlock"

export default function FrenchDatePicker({ setValue, getValue, erasable, ...props }) {
  moment.locale("fr")

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"fr-FR"}>
      <DatePicker
        {...props}
        renderInput={params => (
          <TextFieldBlock
            // style={{ minWidth: 200, marginTop: 40 }}
            {...params}
            InputProps={{
              ...params.InputProps,
              placeholder: "test",
              endAdornment: (
                <React.Fragment>
                  {erasable && getValue() !== null && (
                    <ClearIcon
                      onClick={e => {
                        setValue(null)
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )} 
      />
    </LocalizationProvider>
  )
}
