import React, { useEffect, useState } from "react"

import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Tab,
  Tabs,
} from "@mui/material"

import FrenchDatePicker from "../../forms/FrenchDatePicker"
import ButtonBlock from "../../blocks/ButtonBlock/ButtonBlock"
import { Link, navigate } from "gatsby"
import AutocompleteTheme from "../components/AutocompleteTheme"
import AutocompleteEtablissement from "../components/AutocompleteEtablissement"
import AutocompleteStatut from "../components/AutocompleteStatut"
import callApi from "../../../utils/callApi"

import "./FormationList.scss"
import AutocompleteParticipant from "../components/AutocompleteParticipant"
import AutocompleteOrganisateur from "../components/AutocompleteOrganisateur"
import { getStatusColor } from "../../../utils/status"
import moment from "moment"
import AutocompleteAnimateur from "../components/AutocompleteAnimateur"
import {
  getPersonne,
  getPersonneList,
  getRecontactList,
  isProfilIn,
} from "../../../services/auth"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AutocompleteTypeFormation from "../components/AutocompleteTypeFormation"
import excelExport from "../../../utils/excelHelper"
import RechercheFormationListCard from "../../sections/RechercheFormation/RechercheFormationListCard/RechercheFormationListCard"

const getArrowIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-0.000237385 20C-0.000237868 31.0457 8.89947 40 19.8778 40C30.8562 40 39.7559 31.0457 39.7559 20C39.7559 8.95431 30.8562 -3.89018e-07 19.8778 -8.68897e-07C8.89947 -1.34878e-06 -0.000236903 8.9543 -0.000237385 20Z"
      fill="black"
    />
    <path
      d="M8.3442 20.0001L31.4121 20.0001L19.0636 7.71362"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.3442 20L31.4121 20L19.0636 32.2865"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const FormationList = () => {
  const [theme, setTheme] = useState([])
  const [etablissement, setEtablissement] = useState([])
  const [participant, setParticipant] = useState([])
  const [animateur, setAnimateur] = useState([])
  const [organisateur, setOrganisateur] = useState(
    getPersonne().organisateur ? [getPersonne().organisateur] : []
  )
  const [typeFormation, setTypeFormation] = useState([])
  const [statut, setStatut] = useState([])
  const [dateFrom, setDateFrom] = useState(moment().add(-7, "days"))
  const [dateTo, setDateTo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [serverError, setServerError] = useState()
  const [formations, setFormations] = useState([])
  const [activeCardListButton, setActiveCardListButton] = useState("")
  const [typeDeFormations, setTypeDeFormations] = useState()
  const [clickedCards, setClickedCards] = useState({});

  const handleCardListButtonClick = (buttonId, etablissement) => {
    const Element = document.querySelector(
      `.RechercheFormationListCard.id-${buttonId}`
    )
    const isNewActive = Element && Element.classList.contains("active")

    setClickedCards(prevClickedCards => {
      const updatedClickedCards = { ...prevClickedCards }

      if (isNewActive) {
        updatedClickedCards[buttonId] = !prevClickedCards[buttonId]
      } else {
        Object.keys(updatedClickedCards).forEach(key => {
          updatedClickedCards[key] = false
        })

        updatedClickedCards[buttonId] = true
      }

      return updatedClickedCards
    })
    setActiveCardListButton(prevActiveCardListButton => {
      if (prevActiveCardListButton === buttonId) {
        return null
      }
      return buttonId
    })
    navigate("/mon-espace/priv/details-formation?id=" + buttonId)
  }
  useEffect(() => {
    const fetchData = async () => {
      const params = {}
      theme.length > 0 && (params["theme"] = theme.map(t => t.id))
      etablissement.length > 0 &&
        (params["etablissement"] = etablissement.map(e => e.id))
      participant.length > 0 &&
        (params["participant"] = participant.map(p => p.id))
      organisateur.length > 0 &&
        (params["organisateur"] = organisateur.map(o => o.id))
      animateur.length > 0 &&
        (params["animateur"] = animateur.map(a => a.personne_id))
      statut.length > 0 && (params["statut"] = statut.map(s => s.name))
      dateFrom !== null &&
        (params["dateFrom"] = dateFrom.format("YYYY-MM-DD HH:mm:ss"))
      dateTo !== null &&
        (params["dateTo"] = dateTo.format("YYYY-MM-DD HH:mm:ss"))
      typeFormation.length > 0 &&
        (params["typeFormation"] = typeFormation.map(t => t.id))
      setIsLoading(true)
      try {
        const res = await callApi("get", "/api/formations/find", params)
        setFormations(res.data.evenements)
      } catch (error) {
        setServerError(error.message)
        setIsLoading(false)
      }

      setIsLoading(false)
    }
    fetchData()
  }, [
    theme,
    etablissement,
    participant,
    organisateur,
    animateur,
    statut,
    dateFrom,
    dateTo,
    typeFormation,
  ])

  const getRecontact = async () => {
    try {
      const recontact = await getRecontactList()

      const flattenedRecontactList = recontact.data.recontactList.flat()

      if (
        Array.isArray(flattenedRecontactList) &&
        flattenedRecontactList.length > 0
      ) {
        const sheetName = "Liste recontacts"
        const fileName = "Liste_recontacts.xlsx"
        excelExport(flattenedRecontactList, sheetName, fileName)
      } else {
        console.log("rien a exporter.")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getPersonnesList = async () => {
    try {
      const personnesList = await getPersonneList()
      const personnesListData = personnesList.data.personnesList.flat()

      if (Array.isArray(personnesListData) && personnesListData.length > 0) {
        const sheetName = "Liste personnes"
        const fileName = "listes_personnes.xlsx"
        excelExport(personnesListData, sheetName, fileName)
      } else {
        console.log("rien a exporter.")
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getTypeFormation = async () => {
      try {
        const res = await callApi("get", `/api/type-formation/`)
        setTypeDeFormations(res.data.data)
        console.log(typeDeFormations)
      } catch (error) {
        console.log(error)
      }
    }
    getTypeFormation()
  }, [])
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const renderFormations = filteredFormations =>
    filteredFormations.map(f => (
      <Box
        key={f?.id}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          paddingLeft: "0px",
        }}
      >
        <RechercheFormationListCard
          id={`card-${f?.id}`}
          activeCardListButton={activeCardListButton}
          modalFormation={f}
          formatedDateDebut={moment(f.date_debut).format("DD/MM/YYYY")}
          formatedDateFin={moment(f.date_fin).format("DD/MM/YYYY")}
          typeDeFormation={typeDeFormations}
          onClick={() => handleCardListButtonClick(f?.id, f)}
          clickedCards={clickedCards}
        />
      </Box>
    ))

  const upcomingFormations = renderFormations(
    formations.filter(f => new Date(f.date_debut) > new Date())
  )

  const pastFormations = renderFormations(
    formations.filter(f => {
      const dateDebut = new Date(f.date_debut)
      const dateFinInscription = new Date(dateDebut)
      dateFinInscription.setDate(
        dateDebut.getDate() - f?.formation_type_can_register_until
      )
      return dateFinInscription <= new Date()
    })
  )

  const TabPanel = props => {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              pt: 3,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              pl: "0px",
              pr: "0px",
            }}
          >
            {children.length === 0 ? (
              <p>
                Il n’y a pas de session correspondant aux critères sélectionnés.
              </p>
            ) : (
              children
            )}
          </Box>
        )}
      </div>
    )
  }
const isAdmin = isProfilIn(["Administrateur"])
  return (
    <div className="FormationList">
      <h1 style={{ textAlign: "center", marginTop:"50px" }}>{isAdmin ? "Formations (admin)" : "Formations (Animateur)"} </h1>

      {isProfilIn(["Administrateur"]) && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"10px"}
        >
          <Box>
            <ButtonBlock
              color="orange"
              text="Organiser une formation"
              onClickTrue={() =>
                navigate("/mon-espace/priv/details-formation?id=new")
              }
            />
          </Box>
          <Box>
            <ButtonBlock
              color="orange"
              text="Exporter la liste de toute les personnes"
              onClickTrue={() => getPersonnesList()}
            />
          </Box>
          <Box>
            <ButtonBlock
              color="orange"
              text="Exporter les recontacts"
              onClickTrue={() => getRecontact()}
            />
          </Box>
        </Box>
      )}

      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={5}
        
      >
        <AutocompleteStatut
          multiple
          fullWidth
          value={statut}
          onChange={(e, value) => {
            setStatut(value)
          }}
        />
        {isProfilIn(["Animateur", "Administrateur"]) && (
          <>
            <AutocompleteTypeFormation
              multiple
              fullWidth
              value={typeFormation}
              onChange={(e, value) => {
                setTypeFormation(value)
              }}
            />

            <AutocompleteTheme
              multiple
              fullWidth
              value={theme}
              onChange={(e, value) => {
                setTheme(value)
              }}
            />
          </>
        )}
      </Stack>

      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={5}
        marginTop="30px"
      >
        {isProfilIn(["Animateur", "Administrateur"]) && (
          <AutocompleteEtablissement
            multiple
            fullWidth
            value={etablissement}
            onChange={(e, value) => {
              setEtablissement(value)
            }}
          />
        )}
        {isProfilIn(["Administrateur"]) && (
          <>
            <AutocompleteParticipant
              multiple
              fullWidth
              value={participant}
              onChange={(e, value) => {
                setParticipant(value)
              }}
            />
            <AutocompleteOrganisateur
              multiple
              fullWidth
              value={organisateur}
              onChange={(e, value) => {
                setOrganisateur(value)
              }}
            />
            <AutocompleteAnimateur
              multiple
              fullWidth
              value={animateur}
              onChange={(e, value) => {
                setAnimateur(value)
              }}
            />
          </>
        )}
      </Stack>
      {isProfilIn(["Participant"]) || (
        <Stack
        display={"flex"}
          justifyContent="center"
          alignItems="center"
          spacing={5}
          width={"100%"}
          direction={{
            xs: "column",
            sm: "column",
            md: "row",
          }}
          marginTop="30px"
        >

            <Box width={"50%"}>
              <p>{"A partir de"}</p>
              <FrenchDatePicker
                className="FormationList__DatePicker1"
                erasable
                value={dateFrom}
                onChange={newValue => setDateFrom(newValue)}
                setValue={setDateFrom}
                getValue={() => dateFrom}
                width="100%"
                // label="À partir du"
              />
            </Box>
            &nbsp;&nbsp;&nbsp;
            <Box width={"50%"}>
              <p>{"Jusqu'au"}</p>
              <FrenchDatePicker
                className="FormationList__DatePicker1"
                erasable
                value={dateTo}
                onChange={newValue => setDateTo(newValue)}
                setValue={setDateTo}
                getValue={() => dateTo}
                width="100%"
                // label="Jusqu'au"
              />

          </Box>
        </Stack>
      )}

      {serverError && (
        <Alert severity="error" style={{ marginTop: 50 }}>
          {serverError}
        </Alert>
      )}

      <Box mt={3} mb={5}>
        <Divider />
      </Box>
      {!isLoading && (
        <>
          {/* {formations.map(f => {
            var date_debut = new Date(f.date_debut)
            const formatedDateDebut = moment(f.date_debut).format("DD/MM/YYYY")
            const formatedDateFin = moment(f.date_fin).format("DD/MM/YYYY")
            const dateFinInscription = new Date(date_debut)
            dateFinInscription.setDate(
              date_debut.getDate() - f?.formation_type_can_register_until
            )
            const now = new Date()
            now.setHours(0, 0, 0, 0)
            const isPast = now.getTime() >= dateFinInscription

            return ( */}
          <Container>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              // indicatorColor="primary"
              // textColor="primary"
              centered={false}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "black",
                },
              }}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "start",
                "& .MuiTabs-flexContainer": {
                  background: "transparent",
                  justifyContent: "start",
                },
              }}
            >
              <Tab
                label="À Venir"
                sx={{
                  textTransform: "none",

                  fontSize: "35px",
                  "&.Mui-selected": {
                    color: "black",
                    textTransform: "capitalize",
                  },
                }}
              />
              <Tab
                label="Passées"
                sx={{
                  textTransform: "none",
                  fontSize: "35px",
                  "& .MuiTab-root": {
                    textTransform: "capitalize",
                  },
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
              />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
              {upcomingFormations}
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              {pastFormations}
            </TabPanel>
          </Container>
          {/* )
          })} */}
        </>
      )}

      {isLoading && (
        <Box style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress style={{ color: "black" }} />
        </Box>
      )}

      {formations.length === 0 && (
        <p style={{ marginTop: 50, textAlign: "center" }}>
          {" "}
          Pas de formation trouvée.
        </p>
      )}
      {/* </Table> */}
    </div>
  )
}

export default FormationList
