import { Box, Container } from "@mui/material"
import { navigate } from "gatsby"
import React, { useEffect } from "react"
import {
  getPersonne,
  isLoggedIn,
  isLoggedInAndConfirmed,
  isLoggedInAndConfirmedCgu,
} from "../../services/auth"

import LoginPopUp from "../sections/PopUpsFormationHandler/LoginPopUp/LoginPopUp"
import "./Login.scss"

export default function Login({ redirect }) {
  const personne = getPersonne()
  const isLoggedInAndConfirm = isLoggedInAndConfirmed()
  const isLoggedInCgu = isLoggedInAndConfirmedCgu()
  useEffect(() => {
    const checkLoginStatus = () => {
      console.log(isLoggedInAndConfirm)
      console.log(personne)
      if (isLoggedInAndConfirm && isLoggedInCgu) {
        console.log("navagate from login ")
        navigate(redirect)
      } else if (isLoggedIn()) {
        console.log("Vous n'êtes pas encore confirmé")
      } else {
        return
      }
    }

    checkLoginStatus()
  }, [isLoggedInAndConfirm, isLoggedInCgu, isLoggedIn, navigate, redirect])

  return (
    <Container maxWidth="lg" className="Section">
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <h1>Mon espace</h1>
        <p>Organisateur, Formateur, Aidant, cet espace est le votre.</p>
      </Box>

      <Container maxWidth="lg">
        <LoginPopUp
          openLoginPopUp={false}
          setOpenLoginPopUp={false}
          setInscriptionComplete={false}
          setShowConfirmationStep={false}
          redirect={redirect}
        />
      </Container>
    </Container>
  )
}
