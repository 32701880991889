import CancelIcon from "@mui/icons-material/Cancel"
import SaveIcon from "@mui/icons-material/Save"
import { LoadingButton } from "@mui/lab"
import {
  Alert,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Stepper from "@mui/material/Stepper"
import { Stack } from "@mui/system"
import { navigate } from "gatsby"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import CopyIcon from "../../../images/copy-icon.svg"
import callApi from "../../../utils/callApi"
import CostumBtn from "../../blocks/ButtonBlock/CostumBtn"

const steps = ["Nouvelle", "Soumise", "Publiée", "En cours", "Terminée"]

const FormationStatut = ({ ev_id, isReadOnly }) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isDataUpdating, setIsDataUpdating] = useState(false)

  const [savedStatut, setSavedStatut] = useState()
  const [statut, setStatut] = useState()
  const [isSend, setIsSend] = useState(true)

  const [cancelOpen, setCancelOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [copie, setCopie] = useState(false)

  const loadStatus = useCallback(async () => {
    try {
      const data = await callApi("get", "/api/formation/statut", {
        id: ev_id,
      })
      setStatut(data.data.statut)
      setSavedStatut(data.data.statut)
      setIsDataUpdating(false)
      setIsDataLoaded(true)
      setActiveStep(steps.indexOf(data.data.statut))
    } catch (err) {
      console.log(err.message)
    }
  }, [ev_id])

  const modifyStatus = async statut => {
    setStatut(statut)
  }

  const saveStatus = async () => {
    try {
      setIsDataUpdating(true)
      const data = {
        ev_id,
        statut: statut,
      }
      await callApi("post", "/api/formation/statut", undefined, data)
      setSavedStatut(statut)
      setIsDataUpdating(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    const load = async () => {
      ev_id !== "new" && (await loadStatus())
    }
    load()
  }, [ev_id, loadStatus])

  const [activeStep, setActiveStep] = useState(-1)

  useEffect(() => {
    const modif = async () => {
      if (activeStep > -1) modifyStatus(steps[activeStep])
    }
    modif()
  }, [activeStep])

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleClickCancel = () => {
    setCancelOpen(true)
  }

  const handleCloseCancel = () => {
    setCancelOpen(false)
  }

  const handleConfirmCancel = async () => {
    try {
      const statut = "Annulée"
      const data = {
        ev_id,
        statut,
        isSend: isSend,
      }
      await callApi("post", "/api/formation/cancelformation/", undefined, data)
      setStatut(statut)
      setSavedStatut(statut)
      setCancelOpen(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleClickDelete = e => {
    setDeleteOpen(true)
  }

  const handleCloseDelete = () => {
    setDeleteOpen(false)
  }

  const handleConfirmDelete = async () => {
    try {
      await callApi("delete", "/api/formation/?id=" + ev_id)
      setDeleteOpen(false)
      await navigate("/mon-espace/priv/formations")
    } catch (err) {
      console.log(err.message)
    }
  }
  const copyMessage = document.getElementById("copyMessage")

  const copyUrl = () => {
    navigator.clipboard
      .writeText(process.env.GATSBY_SITE_BASEURL + `/formation/?id=${ev_id}`)
      .then(function () {
        copyMessage.style.display = "inline-block"
        setTimeout(function () {
          copyMessage.style.display = "none"
        }, 500)
      })
    setCopie(true)
  }

  return statut === "Annulée" ? (
    <>
      <Alert severity="warning">Cette Formation est annulée </Alert>
      <Button
        color="error"
        sx={{ mr: 1 }}
        onClick={handleClickDelete}
        disabled={isReadOnly}
      >
        Supprimer cette formation
      </Button>
      <Dialog
        open={deleteOpen}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title2"
        aria-describedby="alert-dialog-description2"
      >
        <DialogTitle id="alert-dialog-title2">
          {"Voulez-vous supprimer cette formation ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description2"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} sx={{ color: "red" }}>
            Supprimer cette formation
          </Button>
          <Button onClick={handleCloseDelete}>NON !</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    ev_id !== "new" && (
      <Box>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 12,
          }}
          id="copyContainer"
        >
          <p>ID: {ev_id}</p>
          <img
            onClick={copyUrl}
            src={CopyIcon}
            width={20}
            height={20}
            style={{ margin: 0, cursor: "pointer" }}
            id="copyIcon"
          />
          <span id="copyMessage">Copié !</span>
        </div>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <h4 style={{ display: "inline" }}>Statut</h4>
          {statut && statut !== savedStatut && isDataLoaded && (
            <Box display={"flex"} gap={"10px"}>
              {statut && statut !== savedStatut && isDataLoaded && (
                <>
                  <CostumBtn
                    className=""
                    text={"Annuler"}
                    border={"1px solid red"}
                    fontSize={"12px"}
                    borderRadius={"10px"}
                    color={"rgba(255, 0, 0, 1)"}
                    borderColor={"rgba(255, 0, 0, 1)"}
                    boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                    iconName={"close"}
                    iconColor={"red"}
                    iconStroke={"red"}
                    iconHeight={"10px"}
                    iconWidth={"10px"}
                    onClick={loadStatus}
                    loading={isDataUpdating}
                    loadingPosition="start"
                    variant="contained"
                    size="small"
                  ></CostumBtn>
                  <CostumBtn
                    text={"Enregistrer >"}
                    border={"1px solid red"}
                    fontSize={"12px"}
                    borderRadius={"10px"}
                    color={"rgba(0, 30, 186, 1)"}
                    borderColor={"rgba(0, 30, 186, 1)"}
                    boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
                    type="submit"
                    loading={isDataUpdating ? true : false}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    size="small"
                    rowReverse={true}
                    onClick={saveStatus}
                  ></CostumBtn>
                </>
              )}
            </Box>
          )}
        </Stack>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStepLabel-root .Mui-completed": {
              color: "black", // circle color (COMPLETED)
              "& .stepperDot": {
                backgroundColor: "rgba(54, 169, 0, 1)",
              },
            },
            // "& .MuiStepLabel-iconContainer":{
            //   display: "none",
            // },
            "& .MuiStepLabel-root .Mui-disabled": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              textTransform: "uppercase",
              fontSize: "10px",
              "& svg": {
                display: "none",
              },
              "& .stepperDot": {
                border: "1px solid black",
              },
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "black", // Just text label (COMPLETED)
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                textTransform: "uppercase",
                fontSize: "10px",
              },
            "& .MuiStepLabel-root .Mui-active": {
              color: "black.500", // circle color (ACTIVE)
            },
            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
              color: "black", // Just text label (ACTIVE)
              textTransform: "uppercase",
              fontSize: "10px",
              fontWeight: 800,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              "& .stepperDot": {
                backgroundColor: "rgba(54, 169, 0, 1)",
              },
            },
            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
              fill: "white", // circle's number (ACTIVE)
            },
            "& .MuiStepLabel-iconContainer": {
              display: "none",
            },
            "& .MuiStepConnector-root	": {
              display: "none",
            },
          }}
        >
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            // if(label===steps[activeStep]) {
            //   labelProps.sx = {
            //     backgroundColor:"#FF0000",
            //     color: ""
            //   }
            // }

            return (
              <Step
                key={label}
                {...stepProps}
                sx={{
                  border: "1px solid black",
                  paddingBottom: "16px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <StepLabel
                  {...labelProps}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box
                    className="stepperDot"
                    height={"10px"}
                    width={"10px"}
                    borderRadius={"50%"}
                  ></Box>
                  <Box>{label}</Box>
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {
          <>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0 || activeStep > 2 || isReadOnly}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Statut précédent
              </Button>
              <Button
                onClick={handleNext}
                color="inherit"
                disabled={activeStep > 1 || isReadOnly}
              >
                Statut suivant
              </Button>

              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                color="error"
                sx={{ mr: 1 }}
                onClick={handleClickCancel}
                disabled={isReadOnly}
              >
                Annuler cette formation
              </Button>
            </Box>
          </>
        }
        <Dialog
          open={cancelOpen}
          onClose={handleCloseCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Voulez-vous annuler cette formation ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSend}
                      onChange={e => setIsSend(e.target.checked)}
                    />
                  }
                  label="Envoyer un email d'annulation aux participants"
                />
              </FormGroup>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmCancel} sx={{ color: "red" }}>
              Annuler cette formation
            </Button>
            <Button onClick={handleCloseCancel}>NON !</Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  )
}
export default FormationStatut
