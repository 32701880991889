import { Popper } from "@mui/material"
import React from "react"
import callApi from "../../../utils/callApi"
import AsyncAutocomplete from "../../forms/AsyncAutocomplete"

async function loadThemes() {
  try {
    const data = await callApi("get", "/api/types-evenements/")
    return [...data.data.data]
  } catch (err) {
    console.log(err.message)
  }
}

const LargePopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  )
}

const AutocompleteTheme = ({ ...props }) => {
  return (
    <AsyncAutocomplete
      {...props}
      label="Thème"
      asyncLoadData={loadThemes}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option?.titre || ""}
      PopperComponent={LargePopper}
    />
  )
}
export default AutocompleteTheme
