import { Box, Stack } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import TextFieldBlock from "../blocks/TextFieldBlock/TextFieldBlock"
import {
  clearAccessToken,
  clearPersonne,
  deleteAccount,
  getPersonne,
  isLoggedIn,
  logout,
} from "../../services/auth"
import { Link, navigate } from "gatsby"
import CostumBtn from "../blocks/ButtonBlock/CostumBtn"
import ButtonBlock from "../blocks/ButtonBlock/ButtonBlock"

function UserInfo() {
  const personne = getPersonne()
  const [isDataDirty, setIsDataDirty] = useState(false)
  const [isDataUpdating, setIsDataUpdating] = useState(false)
  const [id, setId] = useState("")
  const [civilite, setCivilite] = useState("")
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [prevEmail, setPrevEmail] = useState("")
  const [prevMobile, setPrevMobile] = useState("")

  const [fixe, setFixe] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [isConfirmed, setIsConfirmed] = useState("")
  const [isConfirmedCode, setIsConfirmedCode] = useState(false)
  const [isParticipated, setIsParticipated] = useState(false)
  const [message, setMessage] = useState(false)
  const [codeErr, setCodeErr] = useState(false)
  const [open, setOpen] = useState(false)
  const [confirmSms, setConfirmSms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [personneInfo, setPersonneInfo] = useState()

  const loadProfil = useCallback(async () => {
    try {
      setId(personne.id)
      setCivilite(personne.civilite)
      setNom(personne.nom)
      setPrenom(personne.prenom)
      setEmail(personne.email)
      setMobile(personne.mobile)
      setCodePostal(personne.codePostal)
      setFixe(personne.fixe)
      setIsConfirmed(personne.isConfirmed)
      setIsDataDirty(false)
    } catch (err) {
      console.log(err.message)
    }
  }, [personne])

  const HandleLogout = event => {
    logout(() => {})
    if (!isLoggedIn()) navigate("/")
  }
  useEffect(() => {
    loadProfil()
    setPrevEmail(personne.email)
    setPrevMobile(personne.mobile)
  }, [personne.email, personne.mobile])
  const deleteMyAccount = async () => {
    try {
      await deleteAccount(personne.id)
      clearAccessToken()
      clearPersonne()
      navigate("/mon-espace/login/")
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <section className="mon-compte-personne">
      <h2 style={{ textAlign: "center", paddingTop: "30px" }}>Mon compte</h2>
      <form id="">
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="baseline"
          spacing={4}
        ></Stack>
        <div className="personne">
          {/* <Controller
          name="civilite"
          control={control}
          defaultValue=""
          rules={{}}
          render={({ field, fieldState: { error: err } }) => (
            <Select
              {...field}
              select
              label="Civilité"
              labelId="civiliteInput"
              id="civilite"
              value={civilite}
              fullWidth
              error={err !== undefined}
              onChange={e => {
                setCivilite(e.target.value)
                setIsDataDirty(true)
              }}
              // helperText={
              //   err ? errorMessage.civilite[err.type] : " "
              // }
            >
              <MenuItem value={"M"}>M</MenuItem>
              <MenuItem value={"MME"}>Mme</MenuItem>
            </Select>
          )}
        /> */}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              <h4 style={{ display: "inline" }}>
                Mes informations de connexion
              </h4>
              <p>
                Ces informations concernent vos informations de connexion à mon
                espace RePairs Aidants.
              </p>
            </Box>
          </Box>
          {/* </div> */}
          <Stack
            display="flex"
            sx={{
              flexDirection: {
                lg: "row",
                md: "row",
                xs: "column",
              },
            }}
            width="100%"
            alignItems="center"
            gap={"20px"}
            padding={0}
          ></Stack>

          <Box display={"flex"} width={"100%"} flexDirection={"column"}>
            <span>Email</span>

            <TextFieldBlock
              sx={{
                width: {
                  lg: "95%",
                  md: "47%",
                  xs: "100%",
                },
              }}
              value={email}
              type="email"
              id="email"
              readOnly
            />
            <Box display={"flex"} mt={"20px"}>
              <Link
                to="/mon-espace/mot-de-passe-oublie"
                style={{ textDecoration: "none" }}
              >
                <CostumBtn
                  text={"Changer de mot de passe >"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(0, 30, 186, 1)"}
                  borderColor={"rgba(0, 30, 186, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
                  rowReverse={true}
                ></CostumBtn>
              </Link>
              {/* <Box display={"flex"} justifyContent={"center"} pt={3}> */}
              {/* <CostumBtn
                style={{ margin: "0" }}
                className=""
                text={"Supprimer mon compte"}
                border={"1px solid red"}
                fontSize={"12px"}
                borderRadius={"10px"}
                color={"rgba(255, 0, 0, 1)"}
                borderColor={"rgba(255, 0, 0, 1)"}
                boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                iconName={"close"}
                iconColor={"red"}
                iconStroke={"red"}
                iconHeight={"10px"}
                iconWidth={"10px"}
                onClick={() => {
                  if (
                    window.confirm(
                      "Êtes-vous sûr de vouloir supprimer votre compte ? Vous ne pouvez pas récuperer votre compte"
                    )
                  ) {
                    deleteMyAccount()
                  }
                }}
              ></CostumBtn> */}
            </Box>
            {/* </Box> */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <ButtonBlock
                onClickTrue={HandleLogout}
                text="Se deconnecter"
              />
              <CostumBtn
                color="red"
                border={"1px solid red"}
                onClick={() => {
                  if (
                    window.confirm(
                      "Êtes-vous sûr de vouloir supprimer votre compte ? Vous ne pouvez pas récuperer votre compte"
                    )
                  ) {
                    deleteMyAccount()
                  }
                }}
                text="Supprimer mon compte"
              />
            </Box>
          </Box>
        </div>
      </form>
    </section>
  )
}

export default UserInfo
