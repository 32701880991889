import { Link, navigate } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import {
  isLoggedIn,
  getPersonne,
  logout,
  setPersonne,
  deleteAccount,
  clearAccessToken,
  clearPersonne,
} from "../../services/auth"
import ButtonBlock from "../blocks/ButtonBlock/ButtonBlock"
import callApi from "../../utils/callApi"
import "./Profil.scss"
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Cancel"
import { Controller, useForm } from "react-hook-form"
import RoundTextField from "../forms/RoundTextField"
import CostumBtn from "../blocks/ButtonBlock/CostumBtn"
import SavedProfilData from "./components/Profil/SavedProfilData"
import TextFieldBlock from "../blocks/TextFieldBlock/TextFieldBlock"

const Profil = () => {
  const personne = getPersonne()
  const [isDataDirty, setIsDataDirty] = useState(false)
  const [isDataUpdating, setIsDataUpdating] = useState(false)
  const [id, setId] = useState("")
  const [civilite, setCivilite] = useState("")
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [fixe, setFixe] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [isConfirmed, setIsConfirmed] = useState("")
  const [isConfirmedCode, setIsConfirmedCode] = useState(false)
  const [isParticipated, setIsParticipated] = useState(false)
  const [message, setMessage] = useState(false)
  const [codeErr, setCodeErr] = useState(false)
  const [open, setOpen] = useState(false)
  const [confirmSms, setConfirmSms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [personneInfo, setPersonneInfo] = useState()

  const errorMessage = {
    civilite: {
      required: "Champ obligatoire",
    },
    nom: {
      required: "Champ obligatoire",
    },
    prenom: {
      required: "Champ obligatoire",
    },
    code: {
      required: "Champ obligatoire",
    },
    email: {
      // required: "Champ obligatoire",
      pattern: "Cette adresse email est invalide",
    },
    fixe: {
      minLength: "Doit contenir au moins 10 chiffres",
      maxLength: "Doit contenir au plus 10 chiffres",
      pattern: "Doit commencer par 01, 02, 03, 04, 05 ou 09",
    },
    mobile: {
      minLength: "Doit contenir au moins 10 chiffres",
      maxLength: "Doit contenir au plus 10 chiffres",
      pattern: "Doit commencer par 06 ou 07",
    },
    codePostal: {
      // required: "Champ obligatoire",
      pattern: "Code postal invalide",
    },
  }

  const [code] = useState()

  const [error, setError] = useState()

  const { control, watch, handleSubmit, formState } = useForm({
    mode: "onTouched",
  })

  const loadProfil = useCallback(async () => {
    try {
      setId(personne.id)
      setCivilite(personne.civilite)
      setNom(personne.nom)
      setPrenom(personne.prenom)
      setEmail(personne.email)
      setMobile(personne.mobile)
      setCodePostal(personne.codePostal)
      setFixe(personne.fixe)
      setIsConfirmed(personne.isConfirmed)
      setIsDataDirty(false)
    } catch (err) {
      console.log(err.message)
    }
  }, [personne])

  const [validateSendingSms, setValidateSendingSms] = useState(false)
  const [validateSendingEmail, setValidateSendingEmail] = useState(false)
  const [prevEmail, setPrevEmail] = useState("")
  const [prevMobile, setPrevMobile] = useState("")
  const saveProfil = async () => {
    setIsDataUpdating(true)
    const data = {
      id,
      civilite,
      nom,
      prenom,
      email,
      mobile,
      fixe,
      codePostal,
      isConfirmed,
    }

    const user = await getPersonne()
    const currentMobile = user.mobile
    const currentEmail = user.email
    try {
      const updateUser = await callApi(
        "post",
        "/api/auth/updateProfil",
        undefined,
        data
      )
      // setPersonne({ personne: updateUser.data.personne })
      setPersonne({ personne: updateUser.data.personne, isPermanent: true })
      const personnes = updateUser.data.personne
      const isMobileUpdated =
        personnes.mobile && personnes.mobile !== currentMobile
      const isEmailUpdated = personnes.email && personnes.email !== currentEmail

      if (isMobileUpdated || isEmailUpdated) {
        const response = await callApi(
          "post",
          "/api/auth/sendExistedUserEmail",
          null,
          {
            email: isEmailUpdated ? personnes.email : "",
            mobile: isMobileUpdated ? personnes.mobile : "",
            id: personnes.id,
          }
        )
        const result = await response.data

        if (result.success) {
          if (isMobileUpdated) {
            setConfirmSms(true)
            setValidateSendingSms(false)
            const waitForOpenToClose = new Promise(resolve => {
              const interval = setInterval(() => {
                if (!open) {
                  clearInterval(interval)
                  resolve()
                }
              }, 100)
            })

            await waitForOpenToClose
          } else if (isEmailUpdated) setValidateSendingEmail(true)
        } else {
          alert(
            "Erreur: Impossible de confirmer l'adresse e-mail ou le numéro de téléphone."
          )
        }
      } else {
        setIsConfirmedCode(true)
      }

      setIsDataDirty(false)
      setIsDataUpdating(false)
    } catch (error) {
      setError(error.response.data.message)
      setOpen(false)
      setIsDataUpdating(false)
    }
  }

  const HandleLogout = event => {
    logout(() => {})
    if (!isLoggedIn()) navigate("/")
  }
  useEffect(() => {
    loadProfil()
    setPrevEmail(personne.email)
    setPrevMobile(personne.mobile)
  }, [personne.email, personne.mobile])

  const confirmCode = async code => {
    setIsLoading(true)

    const receivedCode = code.code
    try {
      const response = await callApi("post", `/api/auth/confirmCode`, null, {
        code: receivedCode,
      })
      setPersonneInfo({ personne: response.data.personne })
      if (response.data.success) {
        setCodeErr(false)

        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            "personne",
            JSON.stringify(response.data.personne)
          )
        }
        alert("votre numéro de mobile est confirmé !")
        setOpen(false)
        setIsDataDirty(false)
        setIsDataUpdating(false)
        setConfirmSms(false)
        setValidateSendingEmail(false)
        setValidateSendingSms(false)
      } else {
        alert("erreur est servenue")
      }
    } catch (err) {
      setCodeErr(true)
      setIsDataUpdating(false)
    } finally {
      setIsLoading(false)
    }
  }
  const handleSubmiteee = async () => {
    if (email !== prevEmail) {
      setOpen(true)
      if (email === "") {
        setOpen(false)
        saveProfil()
      }
      if (mobile === "" && email !== "") {
        setOpen(false)
        saveProfil()
      }
      setValidateSendingEmail(true)
      setValidateSendingSms(false)
    } else if (mobile !== prevMobile) {
      setOpen(true)
      if (mobile === "" && email === "") {
        setOpen(false)
        saveProfil()
      }
      if (mobile === "" && email !== "") {
        setOpen(false)
        saveProfil()
      }
      setValidateSendingSms(true)
      setValidateSendingEmail(false)
    } else if (email === prevEmail) {
      setOpen(false)
      saveProfil()
    } else if (mobile === prevMobile) {
      setOpen(false)
      saveProfil()
    } else {
      saveProfil()
    }
  }

  return (
    <>
      {/* <div className="titre-page-priv">
        <h1 className="titre">Mon compte</h1>
      </div> */}
      <section className="mon-compte-personne">
        <h2 style={{ textAlign: "center", paddingTop: "30px" }}>
          Mes informations
        </h2>
        <form onSubmit={handleSubmit(handleSubmiteee)} id="">
          <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems="baseline"
            spacing={4}
          ></Stack>
          {error && <Alert severity="error">{error}</Alert>}
          <div className="personne">
            {/* <Controller
              name="civilite"
              control={control}
              defaultValue=""
              rules={{}}
              render={({ field, fieldState: { error: err } }) => (
                <Select
                  {...field}
                  select
                  label="Civilité"
                  labelId="civiliteInput"
                  id="civilite"
                  value={civilite}
                  fullWidth
                  error={err !== undefined}
                  onChange={e => {
                    setCivilite(e.target.value)
                    setIsDataDirty(true)
                  }}
                  // helperText={
                  //   err ? errorMessage.civilite[err.type] : " "
                  // }
                >
                  <MenuItem value={"M"}>M</MenuItem>
                  <MenuItem value={"MME"}>Mme</MenuItem>
                </Select>
              )}
            /> */}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <h4 style={{ display: "inline" }}>Mes informations</h4>
              </Box>
              <Box display={"flex"} gap={"10px"}>
                {isDataDirty && (
                  <>
                    <CostumBtn
                      className=""
                      text={"Annuler"}
                      border={"1px solid red"}
                      fontSize={"12px"}
                      borderRadius={"10px"}
                      color={"rgba(255, 0, 0, 1)"}
                      borderColor={"rgba(255, 0, 0, 1)"}
                      boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                      iconName={"close"}
                      iconColor={"red"}
                      iconStroke={"red"}
                      iconHeight={"10px"}
                      iconWidth={"10px"}
                      onClick={() => {
                        loadProfil()
                      }}
                      loading={isDataUpdating}
                      loadingPosition="start"
                      variant="contained"
                      size="small"
                    ></CostumBtn>
                    <CostumBtn
                      text={"Enregistrer >"}
                      border={"1px solid red"}
                      fontSize={"12px"}
                      borderRadius={"10px"}
                      color={"rgba(0, 30, 186, 1)"}
                      borderColor={"rgba(0, 30, 186, 1)"}
                      boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
                      type="submit"
                      loading={isDataUpdating ? true : false}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      size="small"
                      rowReverse={true}
                    ></CostumBtn>
                  </>
                )}
              </Box>
            </Box>
            {/* </div> */}
            <Stack
              display="flex"
              sx={{
                flexDirection: {
                  lg: "row",
                  md: "row",
                  xs: "column",
                },
              }}
              width="100%"
              alignItems="center"
              gap={"20px"}
              padding={0}
            >
              <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              >
                <span>Prénom</span>
                <Controller
                  name="prenom"
                  control={control}
                  defaultValue=""
                  label="prenom"
                  render={({ field, fieldState: { error: err } }) => (
                    <TextFieldBlock
                      {...field}
                      value={prenom}
                      onChange={e => {
                        const newValue = e.target.value
                        setPrenom(newValue)
                        setIsDataDirty(true)
                        field.onChange(newValue)
                      }}
                      sx={{
                        width: {
                          lg: "100%",
                          md: "50%",
                          xs: "100%",
                        },
                      }}
                      tablet={5}
                      // label="Prénom"
                      type="text"
                      id="prenom"
                      fullWidth
                      error={err !== undefined}
                      helperText={err && errorMessage.prenom[err.type]}
                    />
                  )}
                />
              </Box>

              <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              >
                <span>Nom</span>
              <Controller
                name="nom"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error: err } }) => (
                  <TextFieldBlock
                    {...field}
                    value={nom}
                    onChange={e => {
                      const newValue = e.target.value
                      setNom(newValue)
                      setIsDataDirty(true)
                      field.onChange(newValue)
                    }}
                    type="text"
                    id="nom"
                    fullWidth
                    error={err !== undefined}
                    helperText={err && errorMessage.nom[err.type]}
                  />
                )}
              />
              </Box>
            </Stack>

            <Stack
              display="flex"
              sx={{
                flexDirection: {
                  lg: "row",
                  md: "row",
                  xs: "column",
                },
              }}
              width="100%"
              alignItems="center"
              gap={"20px"}
              padding={0}
            >
                     <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              >
                <span>Code postal</span>
              <Controller
                name="codePostal"
                control={control}
                defaultValue=""
                rules={{
                  pattern: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
                }}
                render={({ field, fieldState: { error: err } }) => (
                  <TextFieldBlock
                    {...field}
                    sx={{
                      width: {
                        lg: "50%",
                        md: "50%",
                        xs: "100%",
                      },
                    }}
                    value={codePostal}
                    onChange={e => {
                      const newValue = e.target.value
                      setCodePostal(newValue)
                      setIsDataDirty(true)
                      field.onChange(newValue)
                    }}
                    type="text"
                    id="codePostal"
                    error={err !== undefined}
                    helperText={err && errorMessage.codePostal[err.type]}
                  />
                )}
              />
              </Box>
            </Stack>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              >
                <span>Email</span>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                // required: true,
                pattern: /^[^@]+@[^@]+\.[^@]+$/,
              }}
              render={({ field, fieldState: { error: err } }) => (
                <TextFieldBlock
                  {...field}
                  sx={{
                    width: {
                      lg: "95%",
                      md: "47%",
                      xs: "100%",
                    },
                  }}
                  value={email}
                  onChange={e => {
                    const newValue = e.target.value
                    setEmail(newValue)
                    setIsDataDirty(true)
                    field.onChange(newValue)
                  }}
                  type="email"
                  id="email"
                  error={err !== undefined}
                  helperText={err && errorMessage.email[err.type]}
                />
              )}
            />
            </Box>
            <Stack
              display="flex"
              sx={{
                flexDirection: {
                  lg: "row",
                  md: "row",
                  xs: "column",
                },
              }}
              width="100%"
              alignItems="center"
              gap={"20px"}
              padding={0}
            >
                     <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              >
                <span>Numéro de mobile</span>
              <Controller
                name="mobile"
                control={control}
                defaultValue=""
                rules={{
                  minLength: 10,
                  maxLength: 10,
                  pattern: /^0[67]/,
                }}
                render={({ field, fieldState: { error: err } }) => (
                  <TextFieldBlock
                    {...field}
                    sx={{
                      width: {
                        lg: "50%",
                        md: "50%",
                        xs: "100%",
                      },
                    }}
                    value={mobile}
                    onChange={e => {
                      const newValue = e.target.value
                      setMobile(newValue)
                      setIsDataDirty(true)
                      field.onChange(newValue) 
                    }}
                    // label="Numéro de mobile"
                    type="tel"
                    error={err !== undefined}
                    helperText={err && errorMessage.mobile[err.type]}
                  />
                )}
                />
                </Box>

                <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              >
                <span>Fixe</span>
              <Controller
                name="fixe"
                control={control}
                defaultValue=""
                rules={{
                  minLength: 10,
                  maxLength: 10,
                  pattern: /^0[1234589]/,
                }}
                render={({ field, fieldState: { error: err } }) => (
                  <TextFieldBlock
                    {...field}
                    sx={{
                      width: {
                        lg: "50%",
                        md: "50%",
                        xs: "100%",
                      },
                    }}
                    value={fixe}
                    onChange={e => {
                      const newValue = e.target.value
                      setFixe(newValue)
                      setIsDataDirty(true)
                      field.onChange(newValue)
                    }}
                    // label="Numéro de fixe"
                    type="tel"
                    error={err !== undefined}
                    helperText={err && errorMessage.fixe[err.type]}
                  />
                )}
              />
              </Box>
            </Stack>
            {personne && personne.profil === "Participant" ? (
              <></>
            ) : (
              <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              >
                <span>Profil</span>
              <Controller
                name="profil"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error: err } }) => (
                  <TextFieldBlock
                    {...field}
                    // label="Profil"
                    value={personne.profil}
                    readonly
                    type="text"
                    id="Profil"
                    fullWidth
                  />
                )}
              />
              </Box>
            )}
          </div>
        </form>
        <Box className="personne" width={"100%"}>
          <SavedProfilData />
        </Box>


        <Dialog open={open}>
          <DialogTitle align="center">
            {validateSendingSms ? (
              <>
                <p style={{ padding: "20px" }}>
                  Vous avez modifié votre numéro de téléphone mobile. Afin de
                  pouvoir vous envoyer un message sur ce numéro, nous devons
                  d'abord le confirmer. Voulez-vous que nous envoyons un message
                  pour confirmer ce numéro ?
                </p>

                <button
                  style={{ marginRight: "20px" }}
                  type="submit"
                  onClick={() => {
                    setValidateSendingEmail(false)
                    saveProfil()
                  }}
                >
                  {isLoading ? "Chargement..." : "Envoyer"}
                </button>
                <button
                  type="submit"
                  // onClickTrue={}
                  onClick={() => {
                    setOpen(false)
                    loadProfil()
                  }}
                >
                  {isLoading ? "Chargement..." : "Annuler"}
                </button>
              </>
            ) : (
              <></>
            )}
            {confirmSms ? (
              <form onSubmit={handleSubmit(confirmCode)} id="confirmCodeForm">
                <p style={{ marginTop: 10, marginBottom: 10 }}>
                  Veuillez saisir le code reçu par SMS :
                </p>
                {codeErr ? (
                  <Alert severity="error">Code incorrect !</Alert>
                ) : (
                  <></>
                )}
                <p></p>
                <Controller
                  name="code"
                  control={control}
                  defaultValue=""
                  value={code}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error: err } }) => (
                    <RoundTextField
                      {...field}
                      tablet={5}
                      label="Code *"
                      type="number"
                      id="code"
                      fullWidth
                      error={err !== undefined}
                      helperText={err ? errorMessage.code[err.type] : " "}
                    />
                  )}
                />
                <button
                  type="submit"
                  form="confirmCodeForm"
                  disabled={isLoading}
                >
                  {isLoading ? "Chargement..." : "Envoyer"}
                </button>
              </form>
            ) : (
              <></>
            )}
            {validateSendingEmail && (
              <>
                <p style={{ padding: "20px" }}>
                  Vous avez modifié votre email. Afin de pouvoir vous envoyer un
                  email sur cette address, nous devons d'abord la confirmer.
                  Voulez-vous que nous envoyons un mail pour la confirmer ?
                </p>

                <button
                  style={{ marginRight: "20px" }}
                  type="submit"
                  onClick={() => {
                    setValidateSendingEmail(false)
                    saveProfil()

                    setOpen(false)
                  }}
                >
                  {isLoading ? "Chargement..." : "Envoyer"}
                </button>
                <button
                  type="submit"
                  // onClickTrue={}
                  onClick={() => {
                    setOpen(false)
                    loadProfil()
                  }}
                >
                  {isLoading ? "Chargement..." : "Annuler"}
                </button>
              </>
            )}
          </DialogTitle>
        </Dialog>
      </section>
    </>
  )
}
export default Profil
