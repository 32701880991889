import React from "react"
import { Box, Button, IconButton, Stack } from "@mui/material"
import FrenchDatePicker from "../../forms/FrenchDatePicker"
import FrenchTimePicker from "../../forms/FrenchTimePicker"
import DeleteIcon from "@mui/icons-material/Delete"
import moment from "moment"

const CreneauxEditor = ({ creneaux, setCreneaux, onChange, isReadOnly }) => {
  const addCreneau = () => {
    setCreneaux(creneaux => {
      const newCreneaux = [
        ...creneaux,
        {
          id: "new-" + Date.now(),
          date: null,
          heure_debut: null,
          heure_fin: null,
        },
      ]
      return newCreneaux
    })
    onChange(creneaux)
  }

  const deleteCreneau = id => {
    setCreneaux(creneaux => creneaux.filter(el => el.id !== id))
    onChange(creneaux)
  }

  const setValueHandler = (id, name, value) => {
    setCreneaux(creneaux =>
      creneaux.map(el =>
        el.id === id
          ? {
              ...el,
              [name]: value, //(name.includes("heure")?value?.format('LT'):value)
            }
          : el
      )
    )
    onChange(creneaux)
  }

  const getValueHandler = (id, name) => {
    for (const el of creneaux) {
      if (el.id === id) {
        return name.includes("heure") ? moment(el[name]) : el[name]
      }
    }
  }

  return (
    <Stack spacing={2} direction={"column"}>
      {creneaux.map((creneau, idx) => {
        return (
          <Stack
            spacing={2}
            direction={"row"}
            key={creneau.id}
            alignItems={"center"}
          >
            <Box width={"100%"} textAlign={"left"}>
              <p>Date</p>
              <FrenchDatePicker
                value={creneau.date}
                onChange={value => setValueHandler(creneau.id, "date", value)}
                setValue={value => setValueHandler(creneau.id, "date", value)}
                getValue={() => getValueHandler(creneau.id, "date")}
                readOnly={isReadOnly}
                width="100%"
              />
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              gap={"20px"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
            >
              <Box display={"flex"} flexDirection={"column"} textAlign={"left"}>
                <p>Heure de début</p>
                <FrenchTimePicker
                  value={creneau.heure_debut}
                  onChange={value =>
                    setValueHandler(creneau.id, "heure_debut", value)
                  }
                  setValue={value =>
                    setValueHandler(creneau.id, "heure_debut", value)
                  }
                  getValue={() => getValueHandler(creneau.id, "heure_debut")}
                  readOnly={isReadOnly}
                  width="100%"
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"} textAlign={"left"}>
                <p>Heure de fin</p>
                <FrenchTimePicker
                  value={creneau.heure_fin}
                  onChange={value =>
                    setValueHandler(creneau.id, "heure_fin", value)
                  }
                  setValue={value =>
                    setValueHandler(creneau.id, "heure_fin", value)
                  }
                  getValue={() => getValueHandler(creneau.id, "heure_fin")}
                  readOnly={isReadOnly}
                  width="100%"
                />
              </Box>

              {isReadOnly || (
                <Box display={"flex"} mt={"25px"}>
                  <IconButton
                    aria-label="Supprimer le créneau"
                    onClick={() => {
                      deleteCreneau(creneau.id)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Stack>
        )
      })}
      {isReadOnly || <Button onClick={addCreneau}>Ajouter un créneau</Button>}
      {/* <pre>{JSON.stringify(creneaux, null, 4)}</pre> */}
    </Stack>
  )
}
export default CreneauxEditor
