import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Box, Tab, Tabs } from "@mui/material"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import React from "react"
import Icon from "../../utils/icon"
import CostumBtn from "../blocks/ButtonBlock/CostumBtn"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
const PrivateTabs = ({ children }) => {
  const { pathname } = useLocation()

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      flexDirection={"column"}
      width={"100%"}
    >
      <Tabs
        value={pathname}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "Bold",
          alignItems: "flex-start",
          "& .MuiTabs-flexContainer": {
            flexDirection: { lg: "row", xs: "column" },
            justifyContent: "space-between",
            paddingBlock: "20px",
            background: "rgba(246, 247, 251, 1)",
            gap: "4px",
          },
        }}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Box
          width={"100%"}
          // display={"flex"}
          flex={1}
          // alignItems={"center"}
          // justifyContent={"space-between"}
          sx={{
            backgroundColor: "white",

            paddingInline: "20px",
          }}
        >
          <Link
            to="/mon-espace/priv/mon-compte/"
            style={{
              display: "flex",
            }}
          >
            <Box width={"100%"} display={"flex"} alignItems={"center"}>
              <Icon icon={"settings"} height={"20px"} width={"21px"} />

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Tab
                  label="Mon compte"
                  to="/mon-espace/priv/mon-compte/"
                  LinkComponent={Link}
                  m={0}
                  disableRipple
                  value={"/mon-espace/priv/mon-compte/"}
                  sx={[
                    {
                      fontWeight: "Bold",
                      "&.Mui-selected": {
                        color: "black",
                      },
                      alignItems: "center",
                    },
                  ]}
                />
                <Box>
                  <span
                    style={{
                      color: "rgba(190, 200, 215, 1)",
                      fontSize: "15px",
                      padding: "15px",
                    }}
                  >
                    Mes informations de connexion
                  </span>
                </Box>
              </Box>
            </Box>
            <Box className={"btn-arrow-right"}>
              <CostumBtn
                text={
                  pathname === "/mon-espace/priv/mon-compte/" ? (
                    <KeyboardArrowDownOutlinedIcon fontSize="large" />
                  ) : (
                    <NavigateNextIcon fontSize="large" />
                  )
                }
              ></CostumBtn>
            </Box>
            <Box className={"btn-arrow-down"}>
              <CostumBtn     
                text={<NavigateBeforeIcon fontSize="large" />}
              ></CostumBtn>
            </Box>
          </Link>
        </Box>
        <Box
          width={"100%"}
          // display={"flex"}
          // alignItems={"center"}
          flex={1}
          // justifyContent={"space-between"}
          sx={{
            backgroundColor: "white",
            paddingInline: "20px",
          }}
        >
          <Link
            to="/mon-espace/priv/information-personnelles"
            style={{
              display: "flex",
            }}
          >
            <Box width={"100%"} display={"flex"} alignItems={"center"}>
              <Icon icon={"profil"} height={"20px"} width={"20px"} />

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Tab
                  label="Information personnelles"
                  to="/mon-espace/priv/information-personnelles"
                  LinkComponent={Link}
                  disableRipple
                  value={"/mon-espace/priv/information-personnelles/"}
                  sx={[
                    {
                      fontWeight: "Bold",
                      "&.Mui-selected": {
                        color: "black",
                      },
                    },
                  ]}
                />
                <Box>
                  <span
                    style={{
                      color: "rgba(190, 200, 215, 1)",
                      fontSize: "15px",
                      padding: "15px",
                    }}
                  >
                    Mes informations et de la personne que j’accompagne
                  </span>
                </Box>
              </Box>
            </Box>

            <Box className={"btn-arrow-right"}>
              <CostumBtn
                text={
                  pathname === "/mon-espace/priv/information-personnelles/" ? (
                    <KeyboardArrowDownOutlinedIcon fontSize="large" />
                  ) : (
                    <NavigateNextIcon fontSize="large" />
                  )
                }
              ></CostumBtn>
            </Box>
            <Box className={"btn-arrow-down"}>
              <CostumBtn
                text={<NavigateBeforeIcon fontSize="large" />}
              ></CostumBtn>
            </Box>
          </Link>
        </Box>
        <Box
          width={"100%"}
          // display={"flex"}
          flex={1}
          // alignItems={"center"}
          // justifyContent={"space-between"}
          sx={{
            backgroundColor: "white",
            paddingInline: "20px",
          }}
        >
          <Link
            to="/mon-espace/priv/formations/"
            style={{
              display: "flex",
            }}
          >
            <Box width={"100%"} display={"flex"} alignItems={"center"}>
              <Icon icon={"users"} height={"24px"} width={"25px"} />
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Tab
                  label="Formations"
                  to="/mon-espace/priv/formations/"
                  LinkComponent={Link}
                  disabled={false}
                  disableRipple
                  value={"/mon-espace/priv/formations"}
                  sx={[
                    {
                      fontWeight: "Bold",
                      "&.Mui-selected": {
                        color: "black",
                      },
                    },
                  ]}
                />
                <Box>
                  <span
                    style={{
                      color: "rgba(190, 200, 215, 1)",
                      fontSize: "15px",
                      padding: "15px",
                    }}
                  >
                    Les formations passées et à venir
                  </span>
                </Box>
              </Box>
            </Box>
            <Box className={"btn-arrow-right"}>
              <CostumBtn
                text={
                  pathname === "/mon-espace/priv/formations/" ? (
                    <KeyboardArrowDownOutlinedIcon fontSize="large" />
                  ) : (
                    <NavigateNextIcon fontSize="large" />
                  )
                }
              ></CostumBtn>
            </Box>
            <Box className={"btn-arrow-down"}>
              <CostumBtn
                text={<NavigateBeforeIcon fontSize="large" />}
              ></CostumBtn>
            </Box>
          </Link>
        </Box>
        {/* <Tab
          label="Details formation"
          to="/mon-espace/priv/details-formation/"
          LinkComponent={Link}
          disableRipple
          value={"/mon-espace/priv/details-formation/"}
          sx={[
            {
              fontWeight: "Bold",
              "&.Mui-selected": {
                color: "black",
              },
            },
          ]}
        /> */}
      </Tabs>

      <Box justifyContent="center" alignItems="top">
        <div className="page-priv">{children}</div>
      </Box>
    </Box>
  )
}
export default PrivateTabs
