import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material"
import React, { useCallback, useState } from "react"
import { sendEmailParticipant, sendSMS } from "../../../../services/auth"
import { LoadingButton } from "@mui/lab"
import { formatDate } from "../../../../utils/dateHelper"

function FormationParticipantListNotificationDialog({
  openDialog,
  handleCloseDialog,
  currentParticipant,
  currentNotification,
  clickedType,
  ev_id,
  setEmailSent,
  emailSent,
  setEmailError,
  emailError,
  setSmsSent,
  smsSent,
  setSmsError,
  smsError,
  getNotificationLabel,
}) {
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [loadingSms, setLoadingSms] = useState(false)

  const sendParticipantEmail = useCallback(
    async ({ code, evId, personneId }) => {
      try {
        setLoadingEmail(true)
        const data = await sendEmailParticipant({
          code: code,
          evId: evId,
          personneId: personneId,
        })
        setEmailSent(true)
        setEmailError(false)
      } catch (err) {
        setEmailError(true)
        setEmailSent(false)
        console.log("error in sendParticipantEmail: ", err)
        throw err
      } finally {
        setLoadingEmail(false)
      }
    },
    []
  )

  const sendParticipantSMS = useCallback(async ({ code, evId, personneId }) => {
    setLoadingSms(true)
    try {
      const data = await sendSMS({
        code: code,
        evId: evId,
        personneId: personneId,
      })
      setSmsSent(true)
      setSmsError(false)
    } catch (err) {
      setSmsError(true)
      setSmsSent(false)
      console.log(err.message)
      throw err
    } finally {
      setLoadingSms(false)
    }
  }, [])
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "20px 24px;",
          flex: "1",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          // flex={"1"}
          width={"100%"}
        >
          <Typography width={"100%"}>{currentParticipant.nom}</Typography>
        </Box>
        <Box display={"flex"} justifyContent={"flex-start"} width={"100%"}>
          <Typography width={"100%"}>{currentParticipant.prenom}</Typography>
        </Box>
      </Box>
      <DialogContent>
        {currentNotification[clickedType] ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid rgba(180, 180, 180, 1)",
              padding: "15px 0px 15px 0px",
              flex: "1",
              width: { lg: "484px", xs: "200px" },
            }}
          >
            <>
              <p>
                <strong>{getNotificationLabel(clickedType)}</strong>
              </p>
              <span style={{textTransform:"uppercase"}}>
                Envoyé le{" "} 
                {currentNotification[clickedType]
                  ? formatDate(currentNotification[clickedType]?.sent_on)
                  : "no date"}
              </span>
            </>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid rgba(180, 180, 180, 1)",
              padding: "15px 0px 15px 0px",
              flex: "1",
              width: { lg: "484px", xs: "200px" },
            }}
          >
            <>
              <p>
                <strong>{getNotificationLabel(clickedType)}</strong>
              </p>
              <span>l'email n'a pas été envoyée</span>
            </>
          </Box>
        )}

        {currentParticipant.email && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { lg: "column", xs: "column" },
              paddingTop: "27px",
              gap: "10px",
              width: "100%",
            }}
          >
            <LoadingButton
              sx={{
                border: "1px solid rgba(0, 30, 186, 1)",
                borderRadius: "10px",
                width: "100%",
              }}
              loading={loadingEmail}
              onClick={() =>
                sendParticipantEmail({
                  code: clickedType,
                  evId: ev_id,
                  personneId: currentParticipant.personne_id,
                })
              }
            >
              Renvoyer l'email
            </LoadingButton>
            {emailSent ? (
              <Alert severity="success">Votre email a bien été envoyé !</Alert>
            ) : (
              emailError && (
                <Alert severity="error">
                  Probleme lors de l'envoie d'email
                </Alert>
              )
            )}
          </Box>
        )}

        {currentParticipant.mobile &&
          !["QUESTIONNAIRE", "EVALUATION"].includes(clickedType) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: { lg: "column", xs: "column" },
                paddingTop: "27px",
                gap: "10px",
                width: "100%",
              }}
            >
              <LoadingButton
                sx={{
                  border: "1px solid rgba(0, 30, 186, 1)",
                  borderRadius: "10px",
                  width: "100%",
                }}
                loading={loadingSms}
                onClick={() =>
                  sendParticipantSMS({
                    code: clickedType,
                    evId: ev_id,
                    personneId: currentParticipant.personne_id,
                  })
                }
              >
                Renvoyer le SMS
              </LoadingButton>
              {smsSent ? (
                <Alert severity="success">Votre SMS a bien été envoyé !</Alert>
              ) : (
                smsError && (
                  <Alert severity="error">
                    Probleme lors de l'envoie du SMS
                  </Alert>
                )
              )}
            </Box>
          )}
        {["QUESTIONNAIRE", "EVALUATION"].includes(clickedType) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { lg: "column", xs: "column" },
              paddingTop: "27px",
              gap: "10px",
              width: "100%",
            }}
          >
            <Button
              sx={{
                border: "1px solid rgba(0, 30, 186, 1)",
                borderRadius: "10px",
                width: "100%",
              }}
              href={`${process.env.GATSBY_SITE_BASEURL}/${
                clickedType === "QUESTIONNAIRE" ? "questionnaire" : "evaluation"
              }/${
                clickedType === "QUESTIONNAIRE"
                  ? currentNotification.questionnaireUrl?.data.slug
                  : currentNotification.evaluationUrl?.data.slug
              }?user_personne_id=${
                currentParticipant.personne_id
              }&ev_id=${ev_id}`}       
              target="_blank"
            >
              Consulter{" "}
              {clickedType === "QUESTIONNAIRE"
                ? "le questionnaire"
                : "l'evaluation"}
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormationParticipantListNotificationDialog
