import { Container, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"

import { isProfilIn } from "../../services/auth"
import callApi from "../../utils/callApi"
import { useLocation } from "@reach/router"
import { parse } from "query-string"

import FormationAnimateursSelector from "./components/FormationAnimateursSelector"
import FormationParticipantsList from "./components/FormationParticipantsList"
import FormationStatutStepper from "./components/FormationStatutStepper"
import FormationEvenementEditor from "./components/FormationEvenementEditor"
import FormationCreneauxEditor from "./components/FormationCreneauxEditor"
import { navigate } from "gatsby"

const DetailsFormation = () => {
  const location = useLocation()
  const searchParams = parse(location.search)
  const [formation, setFormation] = useState()
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [evId, setEvId] = useState(searchParams.id)
  const [statut, setStatut] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (evId === "new") {
          const data = await callApi("post", "/api/formation/")
          if (data.data.formation?.id) {
            await navigate(
              "/mon-espace/priv/details-formation?id=" + data.data.formation.id
            )
            setEvId(data.data.formation.id)
            setStatut("Nouvelle")
          }
        } else {
          const data = await callApi("get", "/api/formation/statut", {
            id: evId,
          })
          setStatut(data.data.statut)
        }
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchData()
  }, [evId])
  useEffect(() => {
    const getFormation = async () => {
      try {
        const formationResponse = await callApi(
          "get",
          "/api/formationdetails/",
          {
            id: evId,
          }
        )
        setFormation(formationResponse.data.formation)
      } catch (error) {
        console.log(error)
      }
    }
    getFormation()
    if (isDataChanged) {
      getFormation()
      setIsDataChanged(false)
    }
  }, [isDataChanged])

  const isReadOnly = !(
    isProfilIn(["Administrateur"])
    //    || (isProfilIn(["Organisateur"]) && statut === "Nouvelle")
  )
  return (
    <Container>
      <h1 style={{ textAlign: "center" }}>Détails de la formation</h1>
      {evId === "new" && <h5>Génération en cours...</h5>}

      <Stack direction={"column"} spacing={12} textAlign={"start"}>
        <FormationStatutStepper ev_id={evId} isReadOnly={isReadOnly} />

        <FormationEvenementEditor
          ev_id={evId}
          isReadOnly={isReadOnly}
          setIsDataChanged={setIsDataChanged}
        />

        <FormationCreneauxEditor
          ev_id={evId}
          isReadOnly={isReadOnly}
          setIsDataChanged={setIsDataChanged}
        />

        <FormationAnimateursSelector ev_id={evId} isReadOnly={isReadOnly} />

        <FormationParticipantsList
          ev_id={evId}
          isReadOnly={isReadOnly}
          formation={formation}
        />
      </Stack>
      <div style={{ marginBottom: 500 }} />
    </Container>
  )
}

export default DetailsFormation
