import React, { useCallback, useEffect, useState } from "react"

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material"

import ButtonBlock from "../../blocks/ButtonBlock/ButtonBlock"
import { Link, navigate } from "gatsby"
import callApi from "../../../utils/callApi"
import arrowDown from "../../../images/arrow-down.svg"

import moment from "moment"
import { slugify } from "../../../utils/slugify"
import {
  getParticipantQuestionnaire,
  getPersonne,
} from "../../../services/auth"
import "./ParticipantFormationList.scss"
import FormationLocation from "../../blocks/FormationLocation/FormationLocation"
import RechercheFormationListCard from "../../sections/RechercheFormation/RechercheFormationListCard/RechercheFormationListCard"
import FormationDetailsPopUp from "../../sections/PopUpsFormationHandler/FormationDetailsPopUp/FormationDetailsPopUp"

const ParticipantFormationList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [serverError, setServerError] = useState()
  const [formations, setFormations] = useState([])
  const [folded, setFolded] = useState(true)
  const [activeCardListButton, setActiveCardListButton] = useState("")
  const [typeDeFormations, setTypeDeFormations] = useState()
  const [open, setOpen] = useState(false)
  const [currentFormation, setCurrentFormation] = useState()
  const [activeGridListButton, setActiveGridListButton] = useState("VueListe")
  const [clickedCards, setClickedCards] = useState({});

  const showInscriptionFormulaire = formationId => {
    let f = formations.find(item => item.id === formationId)
    setCurrentFormation(f)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleCardListButtonClick = (buttonId, etablissement) => {
    const Element = document.querySelector(
      `.RechercheFormationListCard.id-${buttonId}`
    )
    const isNewActive = Element && Element.classList.contains("active")

    setClickedCards(prevClickedCards => {
      const updatedClickedCards = { ...prevClickedCards }

      if (isNewActive) {
        updatedClickedCards[buttonId] = !prevClickedCards[buttonId]
      } else {
        Object.keys(updatedClickedCards).forEach(key => {
          updatedClickedCards[key] = false
        })

        updatedClickedCards[buttonId] = true
      }

      return updatedClickedCards
    })
    setActiveCardListButton(prevActiveCardListButton => {
      if (prevActiveCardListButton === buttonId) {
        return null
      }
      return buttonId
    })
    if (activeGridListButton === "VueListe") {
      showInscriptionFormulaire(buttonId)
    }
  }
  const dateFrom = moment().add(-365, "days")

  const personne = getPersonne()
  const [desinscrireOpen, setDesinscrireOpen] = useState(false)

  const handleClickDesinscrire = e => {
    setDesinscrireOpen(true)
  }

  const handleCloseDesinscrire = () => {
    setDesinscrireOpen(false)
  }

  const handleConfirmDesinscrire = async ev_id => {
    try {
      const params = { ev_id, personne_id: personne.id }
      await callApi("post", "/api/participants/unsubscribe", null, params)
      setDesinscrireOpen(false)
      window.location.reload()
    } catch (error) {
      setServerError(error.message)
    }
  }

  const personneId = personne.id
  const getQuestionnaire = useCallback(
    async evId => {
      try {
        const data = await getParticipantQuestionnaire({
          personneId: personneId,
          evId: evId,
        })
      } catch (err) {
        console.log(err.message)
      }
    },
    [formations.id]
  )

  useEffect(() => {
    const dateFormation = async () => {
      const params = {}
      dateFrom !== null &&
        (params["dateFrom"] = dateFrom.format("YYYY-MM-DD HH:mm:ss"))
      params["participant"] = [personne.id]
      setIsLoading(true)
      try {
        const res = await callApi("get", "/api/formations/find", params)
        setFormations(res.data.evenements)
      } catch (error) {
        setServerError(error.message)
        setIsLoading(false)
      }
      setIsLoading(false)
    }
    dateFormation()
  }, [])

  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const renderFormations = filteredFormations =>
    filteredFormations.map(f => (
      <Box
        key={f?.id}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          paddingLeft: "0px",
        }}
      >
        <RechercheFormationListCard
          id={`card-${f?.id}`}
          activeCardListButton={activeCardListButton}
          modalFormation={f}
          formatedDateDebut={moment(f.date_debut).format("DD/MM/YYYY")}
          formatedDateFin={moment(f.date_fin).format("DD/MM/YYYY")}
          typeDeFormation={typeDeFormations}
          onClick={() => handleCardListButtonClick(f?.id, f)}
          showInscriptionFormulaire={showInscriptionFormulaire}
          clickedCards={clickedCards}
        />
      </Box>
    ))

  const upcomingFormations = renderFormations(
    formations.filter(f => new Date(f.date_debut) > new Date())
  )

  const pastFormations = renderFormations(
    formations.filter(f => {
      const dateDebut = new Date(f.date_debut)
      const dateFinInscription = new Date(dateDebut)
      dateFinInscription.setDate(
        dateDebut.getDate() - f?.formation_type_can_register_until
      )
      return dateFinInscription <= new Date()
    })
  )

  const TabPanel = props => {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              pt: 3,
              pb: 3,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              pl: "0px",
              pr: "0px",
            }}
          >
            {children.length === 0 ? (
              <p>
                Il n’y a pas de session correspondant aux critères sélectionnés.
              </p>
            ) : (
              children
            )}
          </Box>
        )}
      </div>
    )
  }
  return (
    <>
      <div className="titre-page-priv">
        <div className="titre h1">Bienvenue {personne.prenom}</div>
        <p className="baseline">
          Retrouvez ici toutes les formations auxquelles vous êtes inscrit
        </p>
      </div>
      {serverError && (
        <Alert severity="error" style={{ marginTop: 50 }}>
          {serverError}
        </Alert>
      )}

      {!isLoading && (
        <>
          <section className="liste-formations">
            <Container>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                // indicatorColor="primary"
                // textColor="primary"
                centered={false}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "black",
                  },
                }}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "start",
                  "& .MuiTabs-flexContainer": {
                    background: "transparent",
                    justifyContent: "start",
                  },
                }}
              >
                <Tab
                  label="À venir"
                  sx={{
                    textTransform: "none",

                    fontSize: "35px",
                    "&.Mui-selected": {
                      color: "black",
                      textTransform: "capitalize",
                    },
                  }}
                />
                <Tab
                  label="Passées"
                  sx={{
                    textTransform: "none",
                    fontSize: "35px",
                    "& .MuiTab-root": {
                      textTransform: "capitalize",
                    },
                    "&.Mui-selected": {
                      color: "black",
                    },
                  }}
                />
              </Tabs>
              <TabPanel value={selectedTab} index={0}>
                {upcomingFormations}
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                {pastFormations}
              </TabPanel>
            </Container>
          </section>
          <div>
          </div>
        </>
      )}
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <FormationDetailsPopUp
          setOpen={setOpen}
          close={handleClose}
          formation={formations}
          modalFormation={currentFormation}
          handleClose={handleClose}
          handleClickDesinscrire={handleClickDesinscrire}
          handleCloseDesinscrire={handleCloseDesinscrire}
          handleConfirmDesinscrire={handleConfirmDesinscrire}
          desinscrireOpen={desinscrireOpen}
        />
      </Dialog>
      {isLoading && (
        <Box style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress style={{ color: "black" }} />
        </Box>
      )}

      {formations.length === 0 && (
        <p style={{ marginTop: 50, textAlign: "center" }}>
          {" "}
          Vous êtes inscrit à aucune formation à venir.
        </p>
      )}
    </>
  )
}

export default ParticipantFormationList
