import {
  Alert,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
} from "@mui/material"
import React, { useState } from "react"
import RoundTextField from "../forms/RoundTextField"
import AutocompleteEtablissement from "./components/AutocompleteEtablissement"
import AutocompleteTheme from "./components/AutocompleteTheme"

import ButtonBlock from "../blocks/ButtonBlock/ButtonBlock"
import { navigate } from "gatsby"
import { getPersonne, isProfilIn } from "../../services/auth"
import CreneauxEditor from "./components/CreneauxEditor"
import AutocompleteOrganisateur from "./components/AutocompleteOrganisateur"
import callApi from "../../utils/callApi"

const NewFormation = () => {
  const [organisateur, setOrganisateur] = useState(getPersonne().organisateur)
  const [theme, setTheme] = useState()
  const [etablissement, setEtablissement] = useState()
  const [maxParticipants, setMaxParticipants] = useState()
  const [commentaire, setCommentaire] = useState()
  const [infosAcces, setInfosAcces] = useState()
  const [isVisio, setIsVisio] = useState()

  const [error, setError] = useState()

  const [creneaux, setCreneaux] = useState([
    { id: "new-0", date: null, heure_debut: null, heure_fin: null },
  ])

  const creneauxToDbFormat = creneaux => {
    return creneaux
      .map(c => {
        return {
          id: c.id,
          date: c.date?.format("YYYY-MM-DD"),
          heure_debut: c.heure_debut?.format("LT"),
          heure_fin: c.heure_fin?.format("LT"),
        }
      })
      .filter(c => c.date || c.heure_debut || c.heure_fin)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const data = {
      theme,
      etablissement,
      commentaire,
      creneaux: creneauxToDbFormat(creneaux),
      organisateur,
      maxParticipants,
      infosAcces,
      presentiel: !isVisio,
    }
    try {
      await callApi("post", "/api/formation", undefined, data)
      navigate("/mon-espace/priv/formations")
    } catch (e) {
      if (e.response?.data?.message) {
        setError(e.response.data.message)
      } else {
        setError("Erreur inconnue. Merci de réessayer plus tard.")
      }
    }
  }

  return (
    <>
      <h1 style={{ textAlign: "center", marginBottom: 50 }}>
        Nouvelle formation
      </h1>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={8} style={{ marginBottom: 50 }}>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <AutocompleteOrganisateur
                readOnly={!isProfilIn(["Administrateur"])}
                value={organisateur}
                onChange={(e, value) => {
                  setOrganisateur(value)
                }}
              />

              <AutocompleteTheme
                value={theme}
                onChange={(e, value) => {
                  setTheme(value)
                }}
              />

              <AutocompleteEtablissement
                value={etablissement}
                onChange={(e, value) => {
                  setEtablissement(value)
                }}
              />

              <Stack direction="row" spacing={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isVisio}
                        onChange={(e, value) => {
                          setIsVisio(value)
                        }}
                      />
                    }
                    label="Visio-conférence"
                  />
                </FormGroup>

                <RoundTextField
                  type="number"
                  label="Max. participants"
                  value={maxParticipants}
                  onChange={(e, value) => {
                    setMaxParticipants(e.target.value)
                  }}
                />
              </Stack>

              <RoundTextField
                label="Commentaire"
                fullWidth
                multiline
                minRows={2}
                value={commentaire}
                onChange={(e, value) => {
                  setCommentaire(e.target.value)
                }}
              />

              <RoundTextField
                label="Info. accès"
                fullWidth
                multiline
                minRows={2}
                value={infosAcces}
                onChange={(e, value) => {
                  setInfosAcces(e.target.value)
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <CreneauxEditor
              creneaux={creneaux}
              setCreneaux={setCreneaux}
              onChange={creneaux => setCreneaux(creneaux)}
            />
          </Grid>
        </Grid>

        <ButtonBlock
          type="submit"
          color="orange"
          text="Soumettre"
          onClickTrue={() => navigate("/mon-espace/priv/nouvelle-formation")}
        />
      </form>
    </>
  )
}

export default NewFormation
