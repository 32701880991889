import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { Container, TextField } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { changePassword } from "../../services/auth"
import ButtonBlock from "../blocks/ButtonBlock/ButtonBlock"

const ChangePassword = props => {
  // validation form
  const { control, handleSubmit, watch } = useForm({
    mode: "onTouched",
  })

  const [error, setError] = useState("")
  const [isSent, setIsSent] = useState(false)

  const changePasswordToken = props.token

  const passwordfirst = watch("password", "")
  const passwordverif = watch("password2", "")

  const [showPass, setShowPass] = useState(false)

  const onSubmit = async data => {
    try {
      await changePassword({ changePasswordToken, password: data.password })
      setIsSent(true)
    } catch (e) {
      console.log(e)
      setError(e.response.data?.message)
    }
  }

  const errorMessage = {
    password: {
      required: "Un mot de passe est obligatoire",
      pattern:
        "Doit contenir au moins 1 lettre, 1 chiffre et un caractère spécial",
      minLength: "Doit contenir au moins 8 caractères",
      maxLength: "Doit contenir maximum 64 caractères",
      validate: "Le mot de passe ne correspond pas",
    },
  }

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Changement de mot de passe</h1>
      {isSent ? (
        <p style={{ textAlign: "center" }}>
          Le changement de votre mot de passe à bien été pris en compte.
        </p>
      ) : (
        <>
          {error && <p style={{ color: "red" }}> {error}</p>}

          <Container>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ textAlign: "center" }}
            >
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  minLength: 8,
                  maxLength: 64,
                  pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])/,
                }}
                render={({ field, fieldState: { error: err } }) => (
                  <TextField
                    {...field}
                    id="password"
                    label="Nouveau mot de passe"
                    fullWidth
                    type={showPass ? "text" : "password"}
                    error={err !== undefined}
                    helperText={err ? errorMessage.password[err.type] : ""}
                    style={{ marginTop: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPass ? (
                            <Visibility
                              onClick={() => setShowPass(!showPass)}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => setShowPass(!showPass)}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="password2"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  minLength: 8,
                  maxLength: 64,
                  pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])/,
                  validate: value => value === watch("password"),
                }}
                render={({ field, fieldState: { error: err } }) => (
                  <TextField
                    {...field}
                    id="password2"
                    label="Confirmation du mot de passe"
                    fullWidth
                    type={showPass ? "text" : "password"}
                    error={err !== undefined}
                    helperText={err ? errorMessage.password[err.type] : ""}
                    style={{ marginTop: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPass ? (
                            <Visibility
                              onClick={() => setShowPass(!showPass)}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => setShowPass(!showPass)}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              {passwordfirst === passwordverif && passwordfirst !== "" ? (
                <ButtonBlock
                  type="submit"
                  color="orange"
                  text="Changer de mot de passe"
                />
              ) : (
                <ButtonBlock text="Changer de mot de passe" type="button" />
              )}
            </form>
          </Container>
        </>
      )}
    </>
  )
}
export default ChangePassword
