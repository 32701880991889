import { Alert, Container, TextField } from "@mui/material"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import {
  confirmChangePasswordCode,
  forgotPassword,
  setAccessToken,
  setPersonne,
} from "../../services/auth"
import ButtonBlock from "../blocks/ButtonBlock/ButtonBlock"

import { navigate } from "gatsby"
import "./ForgotPassword.scss"

const ForgotPassword = () => {
  const { control, reset, handleSubmit } = useForm({
    mode: "onTouched",
  })

  const errorMessage = {
    email: {
      required: "Une adresse email est obligatoire",
      pattern: "Cette adresse email est invalide",
    },
    mobile: {
      minLength: "Doit contenir au moins 10 chiffres",
      maxLength: "Doit contenir au plus 10 chiffres",
      pattern: "Doit commencer par 06 ou 07",
    },
    code: {
      required: "Champ obligatoire",
    },
  }

  const [error, setError] = useState("")
  const [isSent, setIsSent] = useState(false)
  const [isSentMobile, setIsSentMobile] = useState(false)

  const [isEmail, setIsEmail] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [personneInfo, setPersonneInfo] = useState()
  const [codeErr, setCodeErr] = useState(false)
  const [code] = useState()
  const [confirmToken, setConfirmToken] = useState()
  const [userId, setUserId] = useState()

  const validateEmailOrMobile = value => {
    const isEmail = /\S+@\S+\.\S+/.test(value)
    const isFrenchPhoneNumber =
      /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(value)

    return isEmail || isFrenchPhoneNumber || "Invalid email or phone number"
  }

  console.log(isEmail)

  const onSubmit = async data => {
    const { email, mobile } = data

    const isEmail = /\S+@\S+\.\S+/.test(data.emailOrMobile)
    const isFrenchPhoneNumber =
      /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(data.emailOrMobile)

    if (isEmail || isFrenchPhoneNumber) {
      try {
        const response = await forgotPassword({
          email: isEmail ? data.emailOrMobile : "",
          mobile: isFrenchPhoneNumber ? data.emailOrMobile : "",
        })
        console.log("res", response)
        setUserId(response.data.userId)
        if (response.data.email && !response.data.mobile) {
          setIsEmail(true)
        } else {
          setIsEmail(false)
        }
        setIsSent(true)
      } catch (e) {
        if (e.response?.data?.message) setError(e.response.data.message)
      }
    }
  }

  // const emailSelected = () => {
  //   setIsEmail(true)
  //   reset({ email: "" })
  // }
  // const mobileSelected = () => {
  //   setIsEmail(false)
  //   reset({ email: "" })
  // }

  const confirmCode = async data => {
    const { code } = data
    // const personne = getPersonne()
    // const userId = personne.id
    console.log(userId)
    try {
      const response = await confirmChangePasswordCode({ userId, code })

      if (
        response.success &&
        response.personne.change_password_confirmation_date
      ) {
        setPersonne({ personne: response.personne })
        setAccessToken({ accessToken: response.accessToken })
        navigate(`/mon-espace/changement-mot-de-passe/${code}`)
      } else {
        alert("Code incorrect")
      }
    } catch (error) {
      console.error(error)
      alert(error.response.data.message)
    }
  }

  return (
    <section className="Section">
      <h1 style={{ alignText: "center" }}>Changement de mot de passe</h1>
      {isSent ? (
        <form
          onSubmit={handleSubmit(confirmCode)}
          id="confirmCodeForm"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: "100px",
            paddingLeft: "100px",
          }}
        >
          <p style={{ marginTop: 50, marginBottom: 50 }}>
            Veuillez saisir le code reçu par {isEmail ? "email" : "SMS"} :
          </p>
          {codeErr ? <Alert severity="error">Code incorrect !</Alert> : <></>}
          <p></p>
          <Controller
            name="code"
            control={control}
            defaultValue=""
            value={code}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error: err } }) => (
              <TextField
                {...field}
                tablet={5}
                label="Code *"
                type="text"
                id="code"
                fullWidth
                error={err !== undefined}
                helperText={err ? errorMessage.code[err.type] : " "}
                sx={{
                  width: { lg: "20%", sx: "20%" },
                  margin: "0 auto",
                  marginTop: 5,
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            )}
          />
          <button type="submit" form="confirmCodeForm" disabled={isLoading}>
            {isLoading ? "Chargement..." : "Envoyer"}
          </button>
        </form>
      ) : (
        <>
          <p>
            Veuillez saisir l'adresse email ou le numéro de mobile lié à votre
            espace RePairs Aidants.
          </p>
          <p>
            Un message va y être envoyé contenant les instructions pour changer
            votre mot de passe.
          </p>

          {error && <p style={{ color: "red" }}> {error}</p>}

          <Container component="main" maxWidth="xs">
            <form onSubmit={handleSubmit(onSubmit)}>
              <p style={{ textAlign: "center", marginBottom: "10px" }}>
                Changer votre mot de passe avec :
              </p>
              <Controller
                name="emailOrMobile"
                control={control}
                defaultValue=""
                rules={{
                  validate: validateEmailOrMobile,
                }}
                render={({ field, fieldState: { error: err } }) => (
                  <TextField
                    {...field}
                    sx={{
                      width: {
                        desktop: "90%",
                        tablet: "90%",
                        laptop: "47%",
                        mobile: "100%",
                      },
                    }}
                    label="Adresse email ou mobile"
                    type="text"
                    id="emailOrMobile"
                    error={err !== undefined}
                    helperText={
                      err ? "Adresse email ou numéro de mobile invalide" : " "
                    }
                    variant="outlined"
                  />
                )}
              />
              <ButtonBlock type={"submit"} color={"orange"} text={"Envoyer"} />
            </form>
          </Container>
        </>
      )}
    </section>
  )
}
export default ForgotPassword
