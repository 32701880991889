import React, { useEffect, useState, useCallback } from "react"
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Cancel"
import callApi from "../../../utils/callApi"
import AutocompleteAnimateur from "./AutocompleteAnimateur"
import ButtonBlock from "../../blocks/ButtonBlock/ButtonBlock"
import FreeEmailEditor from "../../FreeEmailEditor"
import "../FormationList/FormationList.scss"
import {
  sendEmailParticipant,
  sendEmailAnimateurs,
} from "../../../services/auth"
import CostumBtn from "../../blocks/ButtonBlock/CostumBtn"

const AnimateursSelector = ({ ev_id, isReadOnly }) => {
  const [isDataDirty, setIsDataDirty] = useState(false)
  const [isDataUpdating, setIsDataUpdating] = useState(false)

  const [selectedAnimateurToAdd, setSelectedAnimateurToAdd] = useState()
  const [animateurs, setAnimateurs] = useState([])

  const [miseEnRelationOpen, setMiseEnRelationlOpen] = useState(false)
  const [miseEnRelationSent, setMiseEnRelationlSent] = useState(false)
  const [miseEnRelationError, setMiseEnRelationlError] = useState(false)

  const loadAnimateurs = useCallback(async () => {
    try {
      const data = await callApi("get", "/api/formation/animateurs", {
        id: ev_id,
      })
      setAnimateurs(data.data.animateurs)
      setIsDataUpdating(false)
      setIsDataDirty(false)
    } catch (err) {
      console.log(err.message)
    }
  }, [ev_id])

  const modifyAnimateurs = async animateurs => {
    setAnimateurs(animateurs)
    setIsDataDirty(true)
    setSelectedAnimateurToAdd("")
  }

  const saveAnimateurs = async () => {
    try {
      setIsDataUpdating(true)
      const data = {
        ev_id,
        animateurs,
      }
      await callApi("post", "/api/formation/animateurs", undefined, data)
      setIsDataDirty(false)
      setIsDataUpdating(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const sendAnimateursEmail = async (code, evId, personneId) => {
    try {
      const data = await sendEmailAnimateurs({
        code,
        evId,
        personneId,
      })
      setMiseEnRelationlSent(true)
      setMiseEnRelationlError(false)
    } catch (err) {
      setMiseEnRelationlError(true)
      setMiseEnRelationlSent(false)
      console.log(err.message)
      throw err
    }
  }

  useEffect(() => {
    const load = async () => {
      ev_id !== "new" && loadAnimateurs()
    }
    load()
  }, [ev_id, loadAnimateurs])

  const handleCloseMiseEnRelation = () => {
    setMiseEnRelationlOpen(false)
    setMiseEnRelationlSent(false)
  }
  const handleOpenMiseEnRelation = () => {
    setMiseEnRelationlOpen(true)
  }
  return (
    ev_id !== "new" && (
      <Box>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="baseline"
          spacing={4}
        >
          <h4 style={{ display: "inline" }}>Animateurs</h4>
          <Box display={"flex"} gap={"10px"}>
            {isDataDirty && (
              <>
                <CostumBtn
                  className=""
                  text={"Annuler"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(255, 0, 0, 1)"}
                  borderColor={"rgba(255, 0, 0, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                  iconName={"close"}
                  iconColor={"red"}
                  iconStroke={"red"}
                  iconHeight={"10px"}
                  iconWidth={"10px"}
                  onClick={loadAnimateurs}
                  loading={isDataUpdating}
                  loadingPosition="start"
                  variant="contained"
                  size="small"
                ></CostumBtn>
                <CostumBtn
                  text={"Enregistrer >"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(0, 30, 186, 1)"}
                  borderColor={"rgba(0, 30, 186, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
                  type="submit"
                  loading={isDataUpdating ? true : false}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  size="small"
                  rowReverse={true}
                  onClick={saveAnimateurs}
                ></CostumBtn>
              </>
            )}
          </Box>
        </Stack>

        {animateurs.length > 1 && !isDataDirty && !isReadOnly && (
          <Toolbar variant="dense">
            {/* <FreeEmailEditor
              ev_id={ev_id}
              recipient_type="ANIMATEURS"
              button_label="Email libre aux animateurs"
              recipient_string={"Animateurs de la formation"}
            /> */}
            <Button
              variant="outlined"
              size="small"
              onClick={handleOpenMiseEnRelation}
            >
              Email de mise en relation
            </Button>
            <Dialog
              open={miseEnRelationOpen}
              onClose={handleCloseMiseEnRelation}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Voulez-vous envoyer l'email de mise en relation ?"}
              </DialogTitle>
              <DialogContent>
                {miseEnRelationSent ? (
                  <Alert severity="success">
                    Votre email a bien été envoyé !
                  </Alert>
                ) : (
                  miseEnRelationError && (
                    <Alert severity="error">
                      Probleme lors de l'envoie d'email
                    </Alert>
                  )
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={miseEnRelationSent}
                  onClick={() => {
                    sendAnimateursEmail(
                      "MISE_EN_RELATION_ANIMATEURS",
                      ev_id,
                      null
                    )
                  }}
                  sx={{ color: "green" }}
                >
                  Envoyer l'email
                </Button>
                <Button onClick={handleCloseMiseEnRelation}>fermer</Button>
              </DialogActions>
            </Dialog>
          </Toolbar>
        )}

        <Grid container spacing={4} style={{ paddingTop: 50 }}>
          {animateurs.map((f, index) => (
            <Grid item xs={3} key={index}>
              <Card sx={{ minWidth: 275 }} key={f.animateur_id}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {f.civilite} {f.nom} {f.prenom}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {f.role}
                  </Typography>
                  <Typography variant="body2">
                    {f.email || " "}&nbsp;
                  </Typography>
                  <Typography variant="body2">
                    {f.mobile || " "}&nbsp;
                  </Typography>
                </CardContent>
                {!isReadOnly && (
                  <CardActions>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setAnimateurs(animateurs =>
                          animateurs.filter(
                            i => i.animateur_id !== f.animateur_id
                          )
                        )
                        setIsDataDirty(true)
                      }}
                    >
                      Supprimer
                    </Button>
                    {/* <FreeEmailEditor
                    ev_id={ev_id}
                    recipient_type="ANIMATEUR"
                    personne_id={f.personne_id}
                    recipient_string={`${f.nom} ${f.prenom} <${f.email}>`}
                    button_label="email"
                  /> */}
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
        {animateurs.length < 2 && !isReadOnly && (
          <Stack
            direction={"column"}
            style={{ width: 500 }}
            justifyContent="flex-start"
            alignItems="normal"
            spacing={2}
            sx={{mt:"20px"}}
          >
            <AutocompleteAnimateur
              fullWidth
              value={selectedAnimateurToAdd}
              onChange={(e, value) => {
                setSelectedAnimateurToAdd(value)
              }}
            />
            &nbsp;
            <Button
              onClick={() => {
                selectedAnimateurToAdd?.animateur_id &&
                  modifyAnimateurs(
                    animateurs.find(
                      f =>
                        f.animateur_id === selectedAnimateurToAdd.animateur_id
                    )
                      ? animateurs
                      : [...animateurs, selectedAnimateurToAdd]
                  )
              }}
            >
              Ajouter
            </Button>
          </Stack>
        )}
      </Box>
    )
  )
}
export default AnimateursSelector
