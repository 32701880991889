import { Autocomplete, Box, CircularProgress, Paper } from "@mui/material"
import * as React from "react"
import RoundTextField from "./RoundTextField"
import TextFieldBlock from "../blocks/TextFieldBlock/TextFieldBlock"

export default function AsyncAutocomplete({ asyncLoadData, label, ...rest }) {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0

  React.useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    (async () => {
      if (active) {
        const data = await asyncLoadData()
        console.log(data)
        setOptions(data)
      }
    })()

    return () => {
      active = false
    }
  }, [loading, asyncLoadData])

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"}>
      <p>{label}</p>
      <Autocomplete
        {...rest}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        options={options}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              "& .MuiAutocomplete-listbox": {
                "& .MuiAutocomplete-option[aria-selected='true']": {
                  bgcolor: "rgba(0, 10, 49, 1)",
                  color: "white",
                  "&.Mui-focused": {
                    bgcolor: "rgba(0, 10, 49, 1)",
                    color: "white",
                  }
                }
              },
              "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
                bgcolor: "rgba(0, 10, 49, 1)",
                color: "white",
              }
            }}
            style={{ background: "rgba(246, 247, 251, 1)" }}
          >
            {children}
          </Paper>
        )}
        loading={loading}
        loadingText="Chargement..."
        noOptionsText="Pas de résultats"
        renderInput={params => (
          <TextFieldBlock
            {...params}
            variant="outlined"
            // label={label}
            // placeholder={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  )
}
