import React, { useEffect, useState, useCallback } from "react"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
} from "@mui/material"

import AutocompleteOrganisateur from "./AutocompleteOrganisateur"
import AutocompleteTheme from "./AutocompleteTheme"
import AutocompleteEtablissement from "./AutocompleteEtablissement"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Cancel"
import RoundTextField from "../../forms/RoundTextField"
import callApi from "../../../utils/callApi"
import { LoadingButton } from "@mui/lab"
import AsyncAutocomplete from "../../forms/AsyncAutocomplete"
import AutocompleteTypeFormation from "./AutocompleteTypeFormation"
import TextFieldBlock from "../../blocks/TextFieldBlock/TextFieldBlock"
import CostumBtn from "../../blocks/ButtonBlock/CostumBtn"

const FormationEvenement = ({ ev_id, isReadOnly, setIsDataChanged }) => {
  const [isDataDirty, setIsDataDirty] = useState(false)
  const [isDataUpdating, setIsDataUpdating] = useState(false)

  const [organisateur, setOrganisateur] = useState({})
  const [theme, setTheme] = useState({})
  const [etablissement, setEtablissement] = useState({})
  const [commentairesAdmin, setCommentairesAdmin] = useState("")
  const [commentairesAnimateurs, setCommentairesAnimateurs] = useState("")
  const [commentairesParticipants, setCommentairesParticipants] = useState("")
  const [commentairesPublics, setCommentairesPublics] = useState("")
  const [maxParticipants, setMaxParticipants] = useState(10)

  const [urlVisio, setUrlVisio] = useState("")
  const [urlQuestionnaire, setUrlQuestionnaire] = useState("")
  const [urlEvaluation, setUrlEvaluation] = useState("")
  const [themeAutreTitle, setThemeAutreTitle] = useState("")
  const [typeFormation, setTypeFormation] = useState({})
  const [isFromUrl, setIsFromUrl] = useState(false)
  const [isFromEv, setIsFromEv] = useState(false)
  const loadEvenement = useCallback(async () => {
    try {
      const data = await callApi("get", "/api/formation/evenement", {
        id: ev_id,
      })
      setOrganisateur(data.data.evenement?.organisateur)
      setTheme(data.data.evenement?.evenementType)
      setEtablissement(data.data.evenement?.etablissement)
      setMaxParticipants(data.data.evenement?.maxParticipants)
      setCommentairesAdmin(data.data.evenement?.commentairesAdmin)
      setCommentairesAnimateurs(data.data.evenement?.commentairesAnimateurs)
      setCommentairesParticipants(data.data.evenement?.commentairesParticipants)
      setCommentairesPublics(data.data.evenement?.commentairesPublics)

      setUrlVisio(data.data.evenement?.urlVisio)
      setUrlQuestionnaire(data.data.evenement?.urlQuestionnaire)
      setUrlEvaluation(data.data.evenement?.urlEvaluation)
      setThemeAutreTitle(data.data.evenement?.themeAutreTitle)
      setTypeFormation(data.data.evenement?.typeFormation)
      setIsDataDirty(false)
    } catch (err) {
      console.log(err.message)
    }
  }, [ev_id])

  const saveEvenement = async () => {
    setIsDataUpdating(true)
    const data = {
      ev_id,
      evenement: {
        organisateur,
        theme,
        etablissement,
        typeFormation,
        urlVisio,
        urlQuestionnaire,
        urlEvaluation,
        themeAutreTitle,
        maxParticipants,
        commentairesAdmin,
        commentairesAnimateurs,
        commentairesParticipants,
        commentairesPublics,
      },
    }
    await callApi("post", "/api/formation/evenement", undefined, data)
    setIsDataDirty(false)
    setIsDataUpdating(false)
    setIsDataChanged(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      ev_id !== "new" && loadEvenement()
    }
    fetchData()
  }, [ev_id, loadEvenement])

  // console.log(theme.titre)
  // loadThemes()
  return (
    ev_id !== "new" && (
      <Box display={"flex"} flexDirection={"column"} gap="20px">
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <h4 style={{ display: "inline", margin: "0px" }}>Évènement</h4>
          <Box display={"flex"} gap={"10px"}>
            {isDataDirty && isFromEv && (
              <>
                <CostumBtn
                  className=""
                  text={"Annuler"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(255, 0, 0, 1)"}
                  borderColor={"rgba(255, 0, 0, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                  iconName={"close"}
                  iconColor={"red"}
                  iconStroke={"red"}
                  iconHeight={"10px"}
                  iconWidth={"10px"}
                  onClick={loadEvenement}
                  loading={isDataUpdating}
                  loadingPosition="start"
                  variant="contained"
                  size="small"
                ></CostumBtn>
                <CostumBtn
                  text={"Enregistrer >"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(0, 30, 186, 1)"}
                  borderColor={"rgba(0, 30, 186, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
                  type="submit"
                  loading={isDataUpdating ? true : false}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  size="small"
                  rowReverse={true}
                  onClick={saveEvenement}
                ></CostumBtn>
              </>
            )}
          </Box>
        </Stack>

        <Stack gap={"20px"} display={"flex"} alignItems={"flex-start"}>
          <AutocompleteTheme
            readOnly={isReadOnly}
            value={theme}
            onChange={(e, value) => {
              setTheme(value)
              setIsDataDirty(true)
              setIsFromEv(true)
              setIsFromUrl(false)
            }}
          />

          {theme && theme.titre === "Autre" ? (
             <Box width={"100%"} textAlign={"left"}>
               <p>Titre de la session</p>
              <TextFieldBlock
                type="text"
                inputProps={{ readOnly: isReadOnly }}
                value={themeAutreTitle}
                onChange={e => {
                  setThemeAutreTitle(e.target.value)
                  setIsDataDirty(true)
                  setIsFromEv(true)
                  setIsFromUrl(false)
                }}
              />
            </Box>
          ) : (
            <></>
          )}
        </Stack>
        <Box gap={"20px"} display={"flex"} alignItems={"start"} >
          <AutocompleteTypeFormation
            readOnly={isReadOnly}
            style={{ width: "100%" }}
            value={typeFormation}
            onChange={(e, value) => {
              const selectedValue = value ? value : null
              setTypeFormation(selectedValue)
              setIsDataDirty(true)
              setIsFromEv(true)
              setIsFromUrl(false)
            }}
          />
          <Box width={"100%"} textAlign={"left"}>
            <p>Max participants</p>
            <TextFieldBlock
              type="number"
              // label="Max. participants"
              inputProps={{ readOnly: isReadOnly }}
              style={{ width: "100%" }}
              value={maxParticipants}
              onChange={e => {
                setMaxParticipants(e.target.value)
                setIsDataDirty(true)
                setIsFromEv(true)
                setIsFromUrl(false)
              }}
            />
          </Box>
        </Box>

        <Stack>
          <AutocompleteOrganisateur
            readOnly={isReadOnly}
            value={organisateur}
            onChange={(e, value) => {
              setOrganisateur(value)
              setIsDataDirty(true)
              setIsFromEv(true)
              setIsFromUrl(false)
            }}
          />
        </Stack>
        <Stack>
          <AutocompleteEtablissement
            readOnly={isReadOnly}
            value={etablissement}
            onChange={(e, value) => {
              setEtablissement(value)
              setIsDataDirty(true)
              setIsFromEv(true)
              setIsFromUrl(false)
            }}
          />
        </Stack>
        <Stack mt={"50px"}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
          >
            <h4 style={{ display: "inline", margin: "0px" }}>URLs</h4>
            <Box display={"flex"} gap={"10px"}>
              {isDataDirty && isFromUrl && (
                <>
                  <CostumBtn
                    className=""
                    text={"Annuler"}
                    border={"1px solid red"}
                    fontSize={"12px"}
                    borderRadius={"10px"}
                    color={"rgba(255, 0, 0, 1)"}
                    borderColor={"rgba(255, 0, 0, 1)"}
                    boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                    iconName={"close"}
                    iconColor={"red"}
                    iconStroke={"red"}
                    iconHeight={"10px"}
                    iconWidth={"10px"}
                    onClick={loadEvenement}
                    loading={isDataUpdating}
                    loadingPosition="start"
                    variant="contained"
                    size="small"
                  ></CostumBtn>
                  <CostumBtn
                    text={"Enregistrer >"}
                    border={"1px solid red"}
                    fontSize={"12px"}
                    borderRadius={"10px"}
                    color={"rgba(0, 30, 186, 1)"}
                    borderColor={"rgba(0, 30, 186, 1)"}
                    boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
                    type="submit"
                    loading={isDataUpdating ? true : false}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    size="small"
                    rowReverse={true}
                    onClick={saveEvenement}
                  ></CostumBtn>
                </>
              )}
            </Box>
          </Stack>
          <Box mt={"20px"}>
            <p>Réunion</p>
            <TextFieldBlock
              type="text"
              // label="URL réunion"
              disabled={!typeFormation?.isRemote}
              inputProps={{ readOnly: isReadOnly }}
              value={urlVisio}
              onChange={e => {
                setUrlVisio(e.target.value)
                setIsDataDirty(true)
                setIsFromUrl(true)
                setIsFromEv(false)
              }}
            />
          </Box>
        </Stack>

        <Stack>
          <h4>Informations</h4>
          <Box display={"flex"} flexDirection={"column"}>
            <Stack
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
              gap={"20px"}
            >
              <Box width={"100%"} textAlign={"left"}>
                <p>Informations privées</p>
                <TextFieldBlock
                  // label="Informations privées"
                  helperText="Visible uniquement par l'administrateur, dans son espace."
                  fullWidth
                  multiline
                  inputProps={{ readOnly: isReadOnly }}
                  minRows={2}
                  maxRows={20}
                  value={commentairesAdmin}
                  onChange={e => {
                    setCommentairesAdmin(e.target.value)
                    setIsDataDirty(true)
                  }}
                />
              </Box>

              <Box width={"100%"} textAlign={"left"}>
                <p>Informations pour les animateurs</p>
                <TextFieldBlock
                  sx={{ mt: 50 }}
                  // label="Informations pour les animateurs"
                  helperText="Visible uniquement par les animateurs de la formation, dans leurs espaces et dans l'email de mise en relation."
                  fullWidth
                  multiline
                  inputProps={{ readOnly: isReadOnly }}
                  minRows={2}
                  value={commentairesAnimateurs}
                  onChange={e => {
                    setCommentairesAnimateurs(e.target.value)
                    setIsDataDirty(true)
                  }}
                />
              </Box>
            </Stack>

            <Stack
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
              gap={"20px"}
            >
              <Box width={"100%"} textAlign={"left"}>
                <p>Informations pour les participants</p>
                <TextFieldBlock
                  // label="Informations pour les participants"
                  helperText="Visible uniquement par les participants à la formation, dans leurs espaces et dans l'email de confirmation d'inscription."
                  fullWidth
                  multiline
                  inputProps={{ readOnly: isReadOnly }}
                  minRows={2}
                  value={commentairesParticipants}
                  onChange={e => {
                    setCommentairesParticipants(e.target.value)
                    setIsDataDirty(true)
                  }}
                />
              </Box>

              <Box width={"100%"} textAlign={"left"}>
                <p>Informations publiques</p>
                <TextFieldBlock
                  // label="Informations publiques"
                  helperText="Visible par tous les visiteurs du site, dans les détails d'une formation."
                  fullWidth
                  multiline
                  inputProps={{ readOnly: isReadOnly }}
                  minRows={2}
                  value={commentairesPublics}
                  onChange={e => {
                    setCommentairesPublics(e.target.value)
                    setIsDataDirty(true)
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    )
  )
}
export default FormationEvenement
