import React from "react"
import { Box } from "@mui/material"

const FormationParticipantsListNotifications = ({
  text,
  sentOn,
  isEmail,
  isSMS,
  media,
  groupedNotifications,
  notifCode,
}) => {
  const getEmailIcon = () =>
    groupedNotifications && sentOn && isEmail ? (
      <img
        style={{ margin: 0 }}
        width={"13px"}
        height={"13px"}
        src="https://spaces-01.fra1.digitaloceanspaces.com//media/upload/961249680a5d3f8fe8d8e007f5e5d912.svg?updated_at=2024-01-16T15:41:42.229Z"
        alt="mail-sent"
      />
      
    ) : (
        <></>
    //   <img
    //     style={{ margin: 0 }}
    //     width={"22px"}
    //     height={"22px"}
    //     src="https://spaces-01.fra1.digitaloceanspaces.com//media/upload/f2a4cd8e5efcea20181e5fbdb6713503.svg?updated_at=2024-01-16T15:41:42.255Z"
    //     alt="mail-sent"
    //   />
    )

  const getSMSIcon = () =>
    groupedNotifications &&
    sentOn &&
    isSMS &&
    !["QUESTIONNAIRE", "EVALUATION"].includes(notifCode) ? (
      <img
        style={{ margin: 0 }}
        width={"13px"}
        height={"13px"}
        src="https://spaces-01.fra1.digitaloceanspaces.com//media/upload/c2824e78f67b8ec7c6a16dddf5dedd72.svg?updated_at=2024-01-16T15:41:42.222Z"
        alt="mail"
      />
    ) : (
    //   !groupedNotifications &&
    //   !sentOn &&
    //   !isSMS &&
    //   ["QUESTIONNAIRE", "EVALUATION"].includes(notifCode) && (
    //     <img
    //       style={{ margin: 0 }}
    //       width={"22px"}
    //       height={"22px"}
    //       src="https://spaces-01.fra1.digitaloceanspaces.com//media/upload/1a90952a1df037b23c1fc013127f2db8.svg?updated_at=2024-01-16T15:41:42.224Z"
    //       alt="mail"
    //     />
    <></>
    //   )
    )

  return (
    <Box display={"flex"} alignItems={"center"} gap={"5px"}>
      {getEmailIcon()}
      {getSMSIcon()}
      <span style={{fontSize:"10px"}}>{text}</span>
    </Box>
  )
}

export default FormationParticipantsListNotifications
