import React, { useEffect, useState } from "react"

import { Box, TextField, Button } from "@mui/material"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Cancel"
import {
  clearPersonne,
  getPersonne,
  getPersonneSurvey,
  setPersonne,
  updatePersonneSurvey,
} from "../../../../services/auth"
import TextFieldBlock from "../../../blocks/TextFieldBlock/TextFieldBlock"
import CostumBtn from "../../../blocks/ButtonBlock/CostumBtn"

function SavedProfilData() {
  const person = getPersonne()
  const survey = person.survey_answers
  const [formData, setFormData] = useState({ ...survey })
  const [isDataDirty, setIsDataDirty] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)

  const handleFieldChange = (fieldName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: { ...prevData[fieldName], value },
    }))
    setIsDataDirty(true)
  }

  const handleCancel = () => {
    setFormData({ ...survey })
    setIsDataDirty(false)
  }

  const onSubmit = async () => {
    try {
      await updatePersonneSurvey({
        surveyAnswers: formData,
        personneId: person.id,
      })
      setIsDataDirty(false)
      setIsSubmited(true)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    const fetchPersonneSurvey = async () => {
      try {
        const updatedSurvey = await getPersonneSurvey({ personneId: person.id })
        const updatedPersonne = {
          ...person,
          survey_answers: updatedSurvey.response.survey_answers,
        }
        setFormData(updatedSurvey.response.survey_answers)
        setIsSubmited(false)
        setPersonne({ personne: updatedPersonne, isPermanent: true })
      } catch (error) {
        console.error(error)
      }
    }

    if (isSubmited) {
      fetchPersonneSurvey()
      setIsDataDirty(false)
    }

    fetchPersonneSurvey()
  }, [isSubmited, setFormData, setIsSubmited])

  return (
    <Box display={"flex"} width={"100%"} flexDirection={"column"} pt="30px">
      <>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={"30px"}
        >
          <Box>
            <h4 style={{ display: "inline" }}>
              La situation de la personne que j’accompagne
            </h4>
          </Box>
          <Box display={"flex"} gap={"10px"}>
            {isDataDirty && (
              <>
                <CostumBtn
                  className=""
                  text={"Annuler"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(255, 0, 0, 1)"}
                  borderColor={"rgba(255, 0, 0, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                  iconName={"close"}
                  iconColor={"red"}
                  iconStroke={"red"}
                  iconHeight={"10px"}
                  iconWidth={"10px"}
                  onClick={() => {
                    handleCancel()
                  }}
                  loadingPosition="start"
                  variant="contained"
                  size="small"
                ></CostumBtn>
                <CostumBtn
                  text={"Enregistrer >"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(0, 30, 186, 1)"}
                  borderColor={"rgba(0, 30, 186, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
                  onClick={() => {
                    onSubmit()
                  }}
                  loadingPosition="start"
                  size="small"
                  rowReverse={true}
                ></CostumBtn>
              </>
            )}
          </Box>
        </Box>
        {formData ? (
          <form
            onSubmit={onSubmit}
            style={{ width: "100%", display: "flex", gap: "20px" }}
          >
            {Object.keys(formData).map(fieldName => (
              <Box key={fieldName} mb={2}>
                {formData[fieldName].value !== null && (
                  <Box display={"flex"} width={"100%"} flexDirection={"column"}>
                    <p>{formData[fieldName].labelProfile}</p>
                    <TextFieldBlock
                      value={formData[fieldName].value}
                      onChange={e =>
                        handleFieldChange(fieldName, e.target.value)
                      }
                    />
                  </Box>
                )}
              </Box>
            ))}
          </form>
        ): (
          <p>Vous n'avez pas encore répondu au questionnaire.</p>
        )}
      </>
    </Box>
  )
}

export default SavedProfilData
