import React from "react"
import { navigate } from "gatsby"
import { isLoggedInAndConfirmed } from "../../services/auth"

const localIsLoggedInAndConfirmed = isLoggedInAndConfirmed

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (
    !localIsLoggedInAndConfirmed() &&
    location.pathname !== `/mon-espace/login`
  ) {
    navigate("/mon-espace/login")
    return null
  }
  return (
    <>
      <Component {...rest} />
    </>
  )
}
export default PrivateRoute
