import { Alert, Dialog, DialogTitle, Grid, MenuItem } from "@mui/material"
import React, { useEffect, useState } from "react"
import callApi from "../../utils/callApi"
import { useLocation } from "@reach/router"
import { Controller, useForm } from "react-hook-form"
import RoundTextField from "../forms/RoundTextField"
import ButtonBlock from "../blocks/ButtonBlock/ButtonBlock"
import { finishRegisterEmail, getPersonne } from "../../services/auth"
import { navigate } from "gatsby"

function ConfirmExistedUser() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("token")

  const [personne, setPersonne] = useState()
  const [unConfirmedUserToken, setUnConfirmationUserToken] = useState(false)
  const [error, setError] = useState()
  const { control, watch, handleSubmit } = useForm({
    mode: "onTouched",
  })
  const [isLoading, setIsLoading] = useState(false)
  const errorMessage = {
    civilite: {
      required: "Champ obligatoire",
    },
    nom: {
      required: "Champ obligatoire",
    },
    prenom: {
      required: "Champ obligatoire",
    },
    code: {
      required: "Champ obligatoire",
    },
    email: {
      // required: "Champ obligatoire",
      pattern: "Cette adresse email est invalide",
    },
    password: {
      required: "Un mot de passe est obligatoire",
      pattern:
        "Doit contenir au moins 1 lettre, 1 chiffre et un caractère spécial",
      minLength: "Doit contenir au moins 8 caractères",
      maxLength: "Doit contenir maximum 64 caractères",
    },
    PwdConfirmation: {
      required: "Un mot de passe est obligatoire",
      pattern:
        "Doit contenir au moins 1 lettre, 1 chiffre et un caractère spécial",
      minLength: "Doit contenir au moins 8 caractères",
      maxLength: "Doit contenir maximum 64 caractères",
      validate: "Le mot de passe ne correspond pas",
    },
    fixe: {
      minLength: "Doit contenir au moins 10 chiffres",
      maxLength: "Doit contenir au plus 10 chiffres",
      pattern: "Doit commencer par 01, 02, 03, 04, 05 ou 09",
    },
    mobile: {
      minLength: "Doit contenir au moins 10 chiffres",
      maxLength: "Doit contenir au plus 10 chiffres",
      pattern: "Doit commencer par 06 ou 07",
    },
    codePostal: {
      required: "Champ obligatoire",
      pattern: "Code postal invalide",
    },
  }

  const [confirmSms, setConfirmSms] = useState(false)
  const existedPersonne = getPersonne()
  const [existingPersn, setExistingPersn] = useState(false)
  const fetchData = async () => {
    try {
      const response = await callApi(
        "post",
        `/api/auth/confirmExistedUserEmail?token=${token}`
      )
      // setIsConfirmed(true)
      setUnConfirmationUserToken(true)
      setPersonne(response.data.personne)

      if (response.data.personne.email && response.data.personne.mobile) {
        setExistingPersn(true)
      }

      if (typeof localStorage !== "undefined") {
        localStorage.setItem("personne", JSON.stringify(response.data.personne))
        localStorage.setItem("accessToken", response.data.accessToken)
      }

      //   return personne
    } catch (e) {
      if (e.response?.data?.message) {
        setError(e.response.data?.message)
        setUnConfirmationUserToken(false)
      } else {
        setError("Erreur inconnue. Merci de réessayer plus tard.")
        setUnConfirmationUserToken(false)
      }
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const [personneInfo, setPersonneInfo] = useState()
  const [open, setOpen] = useState(false)
  const [codeErr, setCodeErr] = useState(false)
  const [code] = useState()
  const [confirmed, setConfirmed] = useState(false)

  const confirmCode = async code => {
    setIsLoading(true)
    // console.log("event", code.code)
    const receivedCode = code.code
    try {
      const response = await callApi("post", `/api/auth/confirmCode`, null, {
        code: receivedCode,
      })
      setPersonneInfo(response.data.personne)
      if (response.data.success) {
        setCodeErr(false)

        if (typeof localStorage !== "undefined") {
          localStorage.setItem(
            "personne",
            JSON.stringify(response.data.personne)
          )
        }
        alert(
          "votre numéro de mobile est confirmer, vous allez être rediriger vers votre profil"
        )
        setOpen(false)
        navigate("/mon-espace/priv/formations/")
      } else {
        alert("erreur est servenue")
      }
    } catch (err) {
      setCodeErr(true)
    } finally {
      setIsLoading(false)
    }
  }
  const onSubmit = async data => {
    setIsLoading(true)

    try {
      setError()
      // const personne = await fetchData()
      // if (!personne) return
      const personneId = personne.id
      const personnex = {
        id: personneId,
        civilite: data.civilite,
        email: personne.email,
        nom: data.nom,
        prenom: data.prenom,
        mobile: data.mobile,
        codePostal: data.codePostal,
        fixe: data.fixe,
        profil: personne.profil,
        isConfirmed: personne.isConfirmed,
      }

      if (typeof localStorage !== "undefined") {
        const previousPersonne = JSON.parse(localStorage.getItem("personne"))
        const updatedPersonne = { ...previousPersonne, ...personnex }
        console.log("perssooon", updatedPersonne)
        await finishRegisterEmail(updatedPersonne)

        if (updatedPersonne.mobile) {
          try {
            const response = await callApi(
              "post",
              `/api/auth/sendExistedUserEmail`,
              null,
              {
                email: "",
                mobile: updatedPersonne.mobile,
                id: updatedPersonne.id,
              }
            )
            const result = await response.data

            if (result.success) {
              setConfirmSms(true)
              setOpen(true)

              const waitForOpenToClose = new Promise(resolve => {
                const interval = setInterval(() => {
                  if (!open) {
                    clearInterval(interval)
                    resolve()
                  }
                }, 100)
              })

              await waitForOpenToClose
            } else {
              alert("erreur...")
            }
          } catch (error) {
            alert(error)
          }
        } else {
          setConfirmed(true)
        }
      }
    } catch (err) {
      setError(err.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const navigateTo = () => {
    navigate("/mon-espace/priv/formations/")
  }
  return (
    <div>
      {unConfirmedUserToken && !confirmed ? (
        <>
          <>
            <Alert severity="success">Votre email a bien été confirmé !</Alert>

            <>
              {existingPersn ? (
                <></>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)} id="handleSubmit">
                  <div className="Formulaire__contain">
                    <h3 style={{ textAlign: "center" }}>
                      veuillez completer le formulaire pour finir votre
                      inscription.
                    </h3>

                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingBottom: 10,
                        // gap: 40
                      }}
                    >
                      <Grid
                        width={"50%"}
                        // height={50}
                      >
                        <Controller
                          name="civilite"
                          control={control}
                          defaultValue=""
                          rules={{}}
                          render={({ field, fieldState: { error: err } }) => (
                            <RoundTextField
                              {...field}
                              sx={{
                                width: {
                                  desktop: "100%",
                                  tablet: "100%",
                                  laptop: "100%",
                                  mobile: "100%",
                                },
                              }}
                              select
                              label="Civilité"
                              labelId="civiliteInput"
                              id="civilite"
                              fullWidth
                              error={err !== undefined}
                              helperText={
                                err ? errorMessage.civilite[err.type] : " "
                              }
                            >
                              <MenuItem value={""}></MenuItem>
                              <MenuItem value={"M"}>M</MenuItem>
                              <MenuItem value={"MME"}>Mme</MenuItem>
                            </RoundTextField>
                          )}
                        />
                      </Grid>

                      <Grid  width={"50%"}>
                        <Controller
                          name="nom"
                          control={control}
                          defaultValue=""
                          rules={
                            {
                              // required: true,
                            }
                          }
                          render={({ field, fieldState: { error: err } }) => (
                            <RoundTextField
                              {...field}
                              label="Nom *"
                              type="text"
                              id="nom"
                              fullWidth
                              error={err !== undefined}
                              helperText={
                                err ? errorMessage.nom[err.type] : " "
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid  width={"50%"}>
                        <Controller
                          name="prenom"
                          control={control}
                          defaultValue=""
                          rules={
                            {
                              // required: true,
                            }
                          }
                          render={({ field, fieldState: { error: err } }) => (
                            <RoundTextField
                              {...field}
                              tablet={5}
                              label="Prénom *"
                              type="text"
                              id="prenom"
                              fullWidth
                              error={err !== undefined}
                              helperText={
                                err ? errorMessage.prenom[err.type] : " "
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid container sx={{ justifyContent: "center", flexDirection: "column" }} width={"50%"} >
                        <Controller
                          name="mobile"
                          control={control}
                          defaultValue=""
                          rules={{
                            minLength: 10,
                            maxLength: 10,
                            pattern: /^0[67]/,
                          }}
                          render={({ field, fieldState: { error: err } }) => (
                            <RoundTextField
                              {...field}
                              sx={{
                                width: {
                                  desktop: "50%",
                                  tablet: "50%",
                                  laptop: "50%",
                                  mobile: "100%",
                                },
                              }}
                              label="Numéro de mobile"
                              type="tel"
                              error={err !== undefined}
                              helperText={
                                err ? errorMessage.mobile[err.type] : " "
                              }
                            />
                          )}
                        />

                        <Controller
                          name="fixe"
                          control={control}
                          defaultValue=""
                          rules={{
                            minLength: 10,
                            maxLength: 10,
                            pattern: /^0[1234589]/,
                          }}
                          render={({ field, fieldState: { error: err } }) => (
                            <RoundTextField
                              {...field}
                              sx={{
                                width: {
                                  desktop: "50%",
                                  tablet: "50%",
                                  laptop: "50%",
                                  mobile: "100%",
                                },
                              }}
                              label="Numéro de fixe"
                              type="tel"
                              error={err !== undefined}
                              helperText={
                                err ? errorMessage.fixe[err.type] : " "
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Controller
                        name="codePostal"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          pattern: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
                        }}
                        render={({ field, fieldState: { error: err } }) => (
                          <RoundTextField
                            {...field}
                            sx={{
                              width: "50%",
                            }}
                            label="code postal *"
                            type="text"
                            id="codePostal"
                            error={err !== undefined}
                            helperText={
                              err ? errorMessage.codePostal[err.type] : " "
                            }
                          />
                        )}
                      />
                    </Grid>

                    <div className="Formulaire__message">
                      <p className="small">
                        <small>
                          <sup>*</sup> Champs obligatoires
                        </small>
                      </p>
                    </div>
                    {error && (
                      <>
                        <Alert severity="error">{error}</Alert>
                      </>
                    )}
                  </div>
                  <div className="Formulaire__btn">
                    <ButtonBlock
                      color="black"
                      text={isLoading ? "Chargement..." : "Envoyer"}
                      type="submit"
                      disabled={isLoading}
                      form="handleSubmit"
                    ></ButtonBlock>
                  </div>
                  <div style={{ textAlign: "center" }}></div>
                </form>
              )}
              <Dialog open={open}>
                <DialogTitle align="center">
                  {confirmSms ? (
                    <form
                      onSubmit={handleSubmit(confirmCode)}
                      id="confirmCodeForm"
                    >
                      <p style={{ marginTop: 50, marginBottom: 50 }}>
                        Vous avez enregistré un numéro de mobile, un SMS vous a
                        été envoyé. Veuillez saisir le code :
                      </p>
                      {codeErr ? (
                        <Alert severity="error">Code incorrect !</Alert>
                      ) : (
                        <></>
                      )}
                      <p></p>
                      <Controller
                        name="code"
                        control={control}
                        defaultValue=""
                        value={code}
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState: { error: err } }) => (
                          <RoundTextField
                            {...field}
                            tablet={5}
                            label="Code *"
                            type="number"
                            id="code"
                            fullWidth
                            error={err !== undefined}
                            helperText={err ? errorMessage.code[err.type] : " "}
                          />
                        )}
                      />
                      <button
                        type="submit"
                        form="confirmCodeForm"
                        disabled={isLoading}
                      >
                        {isLoading ? "Chargement...." : "Envoyer"}
                      </button>
                    </form>
                  ) : (
                    <></>
                  )}
                </DialogTitle>
              </Dialog>
            </>
          </>
        </>
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ margin: "auto", padding: "20" }}>Voir votre profil</p>
            <button onClick={navigateTo}>Profil</button>
          </div>
          {error && (
            <>
              <Alert severity="error">{error}</Alert>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ConfirmExistedUser
