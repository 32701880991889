import { Container } from "@mui/material"
import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layouts/Layout/layout"
import ChangePassword from "../components/private/ChangePassword"
import ConfirmeEmail from "../components/private/ConfirmEmail"
import ConfirmExistedUser from "../components/private/ConfirmExistedUser"
import DetailsFormation from "../components/private/DetailsFormation"
import ForgotPassword from "../components/private/ForgotPassword"
import FormationList from "../components/private/FormationList"
import Login from "../components/private/Login"
import NewFormation from "../components/private/NewFormation"
import ParticipantFormationList from "../components/private/ParticipantFormationList"
import PrivateRoute from "../components/private/PrivateRoute"
import PrivateTabs from "../components/private/PrivateTabs"
import Profil from "../components/private/Profil"
import RgpdPopUp from "../components/sections/RgpdPopUpSection/RgpdPopUp"
import {
  clearAccessToken,
  clearPersonne,
  getPersonne,
  isLoggedIn,
  isLoggedInAndConfirmed,
  isLoggedInAndConfirmedCgu,
  isProfilIn,
  logout,
  setAccessToken,
  setPersonne,
  updateRgpd,
} from "../services/auth"
import UserInfo from "../components/private/UserInfo"

const chemin = [{ titre: "Mon espace" }]

const MonEspace = () => {
  const [cgu, setCgu] = React.useState(true)
  const [charte, setCharte] = React.useState(true)
  const [rgpdOpen, setRgpdOpen] = useState(false)
  const [rgpdError, setRgpdError] = useState("")

  const handleCguChange = event => {
    setCgu(event.target.checked)
  }
  const handleCharteChange = event => {
    setCharte(event.target.checked)
  }
  const isLoggedInAndConfirm = isLoggedInAndConfirmed()
  const isLoggedInCgu = isLoggedInAndConfirmedCgu()

  useEffect(() => {
    const checkLoginStatus = () => {
      if (isLoggedInAndConfirm && !isLoggedInCgu) {
        setRgpdOpen(true)
        console.log("still true")
      } else if (isLoggedIn()) {
        console.log("Vous n'êtes pas encore confirmé")
      } else {
        return
      }
      //  else {
      //   navigate("/mon-espace/priv/formations")
      //   console.log("navagate")
      // }
    }

    checkLoginStatus()
  }, [isLoggedInAndConfirm, isLoggedInCgu, isLoggedIn, navigate])

  const handleRgpdClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log("backdropClicked. Not closing dialog.")
    }
  }

  const handleCloseRgpdPopUp = () => {
    logout(() => {})
    setRgpdOpen(false)
    // if (!isLoggedIn()) navigate("/mon-espace/login/")
  }

  const person = getPersonne()
  const rgpdConfirmationFromEspace = async () => {
    try {
      const response = await updateRgpd({ id: person.id, cgu, charte })
      console.log(response)
      console.log("personne before", person)
      clearAccessToken()
      clearPersonne()
      setAccessToken({
        accessToken: response.accessToken,
        isPermanent: true,
      })
      setPersonne({ personne: response.personne, isPermanent: true })
      setRgpdOpen(false)
      const isLoggedIn = isLoggedInAndConfirmed()
      const isLoggedInCgu = isLoggedInAndConfirmedCgu()

      if (isLoggedIn && isLoggedInCgu) {
        // if (redirect) {
        //   console.log(redirect)
        //   navigate(redirect)
        // }
        // if (openLoginPopUp) {
        //   setOpenLoginPopUp(false)
        // }
      }
    } catch (err) {
      setRgpdError(err.response?.data?.message)
      console.log(err)
    }
  }

  return (
    <Layout chemin={chemin} showPartners={false}>
      {rgpdOpen && (
        <RgpdPopUp
          isOpen={rgpdOpen}
          handleClose={handleRgpdClose}
          handleCloseRgpdPopUp={handleCloseRgpdPopUp}
          handleCguChange={handleCguChange}
          handleCharteChange={handleCharteChange}
          cgu={cgu}
          setCgu={setCgu}
          charte={charte}
          setCharte={setCharte}
          rgpdConfirmation={rgpdConfirmationFromEspace}
          rgpdError={rgpdError}
          setRgpdError={setRgpdError}
        />
      )}
      <Container disableGutters maxWidth="xxl" sx={{textAlign:"start"}}>
        <Router basepath="/mon-espace">
          <PrivateTabs path="/priv">
            <PrivateRoute path="/information-personnelles" component={Profil} />
            <PrivateRoute
              path="/formations"
              component={
                isProfilIn(["Participant"])
                  ? ParticipantFormationList
                  : FormationList
              }
            />
            <PrivateRoute path="/mon-compte" component={UserInfo} />
            <PrivateRoute path="/nouvelle-formation" component={NewFormation} />
            <PrivateRoute
              path="/details-formation"
              component={DetailsFormation}
            />
          </PrivateTabs>
          <Login path="/" redirect="/mon-espace/priv/formations" />
          <Login path="/login" redirect="/mon-espace/priv/formations" />
          <ForgotPassword path="/mot-de-passe-oublie" />
          <ChangePassword path="/changement-mot-de-passe/:token" />
          <ConfirmeEmail path="/confirme-email" />
          <ConfirmExistedUser path="/confirme-existed-user-email" />
        </Router>
      </Container>
    </Layout>
  )
}

export default MonEspace
