import { LoadingButton } from "@mui/lab"
import { Box, Stack } from "@mui/material"
import moment from "moment"
import React, { useEffect, useState, useCallback } from "react"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Cancel"
import callApi from "../../../utils/callApi"

import CreneauxEditor from "./CreneauxEditor"
import CostumBtn from "../../blocks/ButtonBlock/CostumBtn"

const FormationsCreneaux = ({ ev_id, isReadOnly, setIsDataChanged }) => {
  const [isDataDirty, setIsDataDirty] = useState(false)
  const [isDataUpdating, setIsDataUpdating] = useState(false)

  const [creneaux, setCreneaux] = useState([])

  const loadCreneaux = useCallback(async () => {
    try {
      const data = await callApi("get", "/api/formation/creneaux", {
        id: ev_id,
      })

      setCreneaux(
        data.data.creneaux?.map(c => ({
          id: c.id,
          date: moment(c.date),
          heure_debut: moment(c.heure_debut, "HH:mm"),
          heure_fin: moment(c.heure_fin, "HH:mm"),
        }))
      )

      setIsDataDirty(false)
    } catch (err) {
      console.log(err.message)
    }
  }, [ev_id])

  const creneauxToDbFormat = cs => {
    return cs.map(c => {
      return {
        id: c.id,
        date: c.date?.format("YYYY-MM-DD"),
        heure_debut: c.heure_debut?.format("LT"),
        heure_fin: c.heure_fin?.format("LT"),
      }
    })
  }

  const saveCreneaux = async () => {
    try {
      setIsDataUpdating(true)
      const cs = creneaux
      const cs2 = cs.filter(c => c.date && c.heure_debut && c.heure_fin)
      const dbCreneaux = creneauxToDbFormat(cs2)

      const data = {
        ev_id,
        creneaux: dbCreneaux,
      }
      await callApi("post", "/api/formation/creneaux", undefined, data)
      setCreneaux(cs2)
      setIsDataDirty(false)
      setIsDataUpdating(false)
      setIsDataChanged(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      ev_id !== "new" && loadCreneaux()
    }
    fetchData()
  }, [ev_id, loadCreneaux])

  return (
    ev_id !== "new" && (
      <Box>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          mb={5}
        >
          <h4 style={{ display: "inline", margin: "0px" }}>
            Créneaux horaires
          </h4>
          <Box display={"flex"} gap={"10px"}>
            {isDataDirty && (
              <>
                <CostumBtn
                  className=""
                  text={"Annuler"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(255, 0, 0, 1)"}
                  borderColor={"rgba(255, 0, 0, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(255,0,0,0.28)"}
                  iconName={"close"}
                  iconColor={"red"}
                  iconStroke={"red"}
                  iconHeight={"10px"}
                  iconWidth={"10px"}
                  onClick={loadCreneaux}
                  loading={isDataUpdating}
                  loadingPosition="start"
                  variant="contained"
                  size="small"
                ></CostumBtn>
                <CostumBtn
                  text={"Enregistrer >"}
                  border={"1px solid red"}
                  fontSize={"12px"}
                  borderRadius={"10px"}
                  color={"rgba(0, 30, 186, 1)"}
                  borderColor={"rgba(0, 30, 186, 1)"}
                  boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
                  type="submit"
                  loading={isDataUpdating ? true : false}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  size="small"
                  rowReverse={true}
                  onClick={saveCreneaux}
                ></CostumBtn>
              </>
            )}
          </Box>
        </Stack>
        <CreneauxEditor
          creneaux={creneaux}
          setCreneaux={setCreneaux}
          onChange={creneaux => {
            setIsDataDirty(true)
          }}
          isReadOnly={isReadOnly}
        />
      </Box>
    )
  )
}
export default FormationsCreneaux
