import { Popper } from "@mui/material"
import React from "react"
import callApi from "../../../utils/callApi"
import AsyncAutocomplete from "../../forms/AsyncAutocomplete"

async function loadAnimateurs() {
  try {
    const data = await callApi("get", "/api/personnes/animateurs")
    return [...data.data.data]
  } catch (err) {
    console.log(err.message)
  }
}

const LargePopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  )
}

const AutocompleteAnimateur = ({ ...props }) => {
  return (
    <AsyncAutocomplete
      {...props}
      label="Animateur"
      asyncLoadData={loadAnimateurs}
      isOptionEqualToValue={(option, value) =>
        option.animateur_id === value.animateur_id
      }
      getOptionLabel={option =>
        `${option.nom} ${option.prenom} <${option.role}>`
      }
      PopperComponent={LargePopper}
    />
  )
}
export default AutocompleteAnimateur
