import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import MUIRichTextEditor from "mui-rte"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { convertToHTML } from "draft-convert"
import callApi from "../utils/callApi"
import CostumBtn from "./blocks/ButtonBlock/CostumBtn"

export default function FreeEmailEditor({
  ev_id,
  recipient_type,
  personne_id,
  recipient_string,
  button_label = "Email libre",
}) {
  const [open, setOpen] = React.useState(false)
  const [subject, setSubject] = React.useState("")
  const [body, setBody] = React.useState("")

  const theme = createTheme({})

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSend = async () => {
    const data = {
      evenement_id: ev_id,
      recipient_type,
      personne_id,
      subject,
      body,
    }
    await callApi("post", "/api/free-email", {}, data)
    setOpen(false)
  }

  const onEditorChange = event => {
    //const plainText = event.getCurrentContent().getPlainText() // for plain text
    const rteContent = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>
        }
        return originalText
      },
    })(event.getCurrentContent()) // for rte content with text formating
    rteContent && setBody(rteContent) // store your rteContent to state
  }

  return (
    <>
      <CostumBtn
        className="modal-btn"
        text={button_label +" >"}
        border={"1px solid red"}
        fontSize={"12px"}
        borderRadius={"10px"}
        color={"rgba(0, 30, 186, 1)"}
        borderColor={"rgba(0, 30, 186, 1)"}
        boxShadow={"1px 1px 10px 1px rgba(0,30,186,0.27)"}
        onClick={handleClickOpen}
        rowReverse={true}
      ></CostumBtn>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "60%",
            height: "80%",
          },
        }}
      >
        <DialogTitle>Envoyer un email libre</DialogTitle>
        <DialogContent>
          <DialogContentText>À : {recipient_string}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="subject"
            label="Sujet"
            type="text"
            fullWidth
            variant="standard"
            value={subject}
            onChange={e => {
              setSubject(e.target.value)
            }}
          />
          <ThemeProvider theme={theme}>
            <MUIRichTextEditor
              label="Saisissez le contenu ici..."
              controls={[
                "title",
                "bold",
                "italic",
                "underline",
                "link",
                "bulletList",
                "clear",
              ]}
              onChange={onEditorChange}
            />
          </ThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSend}>Envoyer</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
