import * as XLSX from "xlsx";

const excelExport = (json, sheetName, fileName) => {
  const isArray = Array.isArray(json);
  const sheetData = isArray ? json : [json];

  const worksheet = XLSX.utils.json_to_sheet(sheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, fileName);
};

export default excelExport;
