import { Popper } from "@mui/material"
import React from "react"
import callApi from "../../../utils/callApi"
import AsyncAutocomplete from "../../forms/AsyncAutocomplete"

async function loadTypeFormation() {
  try {
    const data = await callApi("get", `/api/type-formation/`)
    return [...data.data.data]
  } catch (err) {
    console.log(err.message)
  }
}
const LargePopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  )
}

const AutocompleteTypeFormation = ({ ...props }) => {
  return (
    <AsyncAutocomplete
      {...props}
      label="Type de Formation"
      asyncLoadData={loadTypeFormation}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option?.label || ""}
      PopperComponent={LargePopper}
    />
  )
}
export default AutocompleteTypeFormation
