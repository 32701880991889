import { Popper } from "@mui/material"
import React from "react"
import callApi from "../../../utils/callApi"
import AsyncAutocomplete from "../../forms/AsyncAutocomplete"

async function loadOrganisateurs() {
  try {
    const data = await callApi("get", "/api/personnes/organisateurs")
    return [...data.data.data]
  } catch (err) {
    console.log(err.message)
  }
}

const LargePopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  )
}

const AutocompleteOrganisateur = ({ ...props }) => {
  return (
    <AsyncAutocomplete
      {...props}
      label="Organisateur"
      asyncLoadData={loadOrganisateurs}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => `${option.entiteJuridique}`}
      PopperComponent={LargePopper}
    />
  )
}
export default AutocompleteOrganisateur
